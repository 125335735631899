import React, { Component } from 'react';
import styles from '../../styles/outsource/outsource.module.css'
import Tables from './tables'

class OutSource extends Component {
  
  render() {
    return (
        <div className=" col-12 mr-auto ml-auto pb-5 pl-0 pr-0"  >
           
           {/* bread crumb */}
            <div className={`breadcrumb_div`} >
                <h2 className="text_color_white text-center pt-5" > OUTSOURCE </h2>
                 <nav aria-label="breadcrumb ml-auto mr-auto text-center">
                    <ol className="breadcrumb justify-content-center" style={{ backgroundColor : 'transparent' }}>
                        <li className="breadcrumb-item"><a href="/">Home</a></li>
                        <li className="breadcrumb-item active text_color_white" aria-current="page">Outsource</li>
                    </ol>
                </nav>
            </div>

            <div className="col-12 ml-auto mr-auto div_max_width" >
            
            <div className="col-12 ml-auto mr-auto"  style={{ margin : '6% 0px' }}>

                <h2 className={`col-12 text-center ml-auto mr-auto font-weight-bold text_color_light_black  ${styles.font_heading} `} > 7CTECH OFFERING FOLLOWING OUTSOURCING SERVICES </h2>

                <div className={`col-12 text-left ml-auto mr-auto row`} style={{ marginTop : '3%' }}>
                   <div className="pt-3 m-0" >
                        <b className={`${styles.rounded_border_text}`} style={{ backgroundColor : '#9b4ce4' }} > 1</b> 
                   </div>
                   <div className="col-9 text-left mt-2" >
                      <b className={`${styles.font_heading} font-weight-light`} style={{ color : '#9b4ce4' }} >  Dedicated Teams (Full Department) </b>
                   </div>
                </div>

                <div className={`col-12 text-left ml-auto mr-auto row`} style={{ marginTop : '3%' }}>
                   <div className="pt-3 m-0" >
                        <b className={`${styles.rounded_border_text}`} style={{ backgroundColor : '#4f67e1' }} > 2</b> 
                   </div>
                   <div className="col-9 text-left mt-2" >
                      <b className={`${styles.font_heading} font-weight-light`} style={{ color : '#4f67e1'  }} >   Project Based </b>
                   </div>
                </div>

                <div className={`col-12 text-left ml-auto mr-auto row`} style={{ marginTop : '3%' }}>
                   <div className="pt-3 m-0" >
                        <b className={`${styles.rounded_border_text}`} style={{ backgroundColor : '#00bcd4' }} > 3</b> 
                   </div>
                   <div className="col-9 text-left mt-2" >
                      <b className={`${styles.font_heading} font-weight-light`} style={{ color : '#00bcd4' }} >   Dedicated Resource (One or Many) </b>
                   </div>
                </div>


            </div>

            {/* table  */}
            <Tables/>

            {/* Dedicated Teams (Full Department) */}
            <div className="ml-auto mr-auto col-12 col-lg-11 div_max_width" style={{ marginTop : '5%' }} >
               
               <div className={`col-12 text-left ml-auto mr-auto row`} style={{ margin : '3%' }}>
                   <div className="pt-3 m-0" >
                        <b className={`${styles.rounded_border_text}`} style={{ backgroundColor : '#9b4ce4' }} > 1</b> 
                   </div>
                   <div className="col-9 text-left mt-2" >
                      <b className={`${styles.font_heading} font-weight-light`} style={{ color : '#9b4ce4'  }} >  Dedicated Teams (Full Department) </b>
                   </div>
                </div>

                <p className={`col-12  ml-auto mr-auto ${styles.outsource_description} text_color_grey` } >
                    Perhaps you want to grow your business quickly and do not want to go into the process of hiring the full team, and managing the complexities of team creation as well as making sure you have the full technical spectrum?
                </p>

                <p className={`col-12 ml-auto mr-auto ${styles.outsource_description} text_color_grey `} >
                    Perhaps you have the diverse needs? You need web development and you need some software developed for it too! But you may need all that for just 6 months or so. No one would like to create a full software department in house for this.
                </p>

                <p className={`col-12 ml-auto mr-auto ${styles.outsource_description} text_color_grey `} >
                    7CTECH has expert resources and technically advanced infrastructure. 7CTECH has diverse resources that can be “rented” or “bought” for a certain period or for “pay as you go”, indefinite time frame.
                </p>

                <p className={`col-12 ml-auto mr-auto ${styles.outsource_description} text_color_grey `} >
                    7CTECH has provided such service to the companies in many parts of the world for over decade. Effectively cutting the cost for our happy clients and making there business grow by heaps. 7CTECH’s dedicated teams are rally the best GROWTH HACK you can have.
                </p>

            </div>

            {/* Project Based */}
            <div className="ml-auto mr-auto col-12 col-lg-11 div_max_width " style={{ marginTop : '5%' }} >
               
               <div className={`col-12 text-left ml-auto mr-auto row`} style={{ margin : '3%' }}>
                   <div className="pt-3 m-0" >
                        <b className={`${styles.rounded_border_text}`} style={{ backgroundColor : '#4f67e1' }} > 2</b> 
                   </div>
                   <div className="col-9 text-left mt-2" >
                      <b className={`${styles.font_heading} font-weight-light`} style={{ color : '#4f67e1'  }} >  Project Based </b>
                   </div>
                </div>

                <p className={`col-12 ml-auto mr-auto ${styles.outsource_description} text_color_grey`} >
                  If you have a killer idea or a great startup thought lingering around in your mind for a long time? Or you want to have a nice mobile application for the online eCommerce that you have. But you have limited budget as well as you do not know the detailed technical aspect of how it gets executed.
                </p>

                <p className={`col-12 ml-auto mr-auto ${styles.outsource_description} text_color_grey`} >
                  Then you do not need to worry any more. 7CTECH offers end to end project based outsourcing services. We at 7CTECH believe that a project does not only depend on creating what is asked for rather we believe in creating and taking proud in success. We at 7CTECH believe that any project trusted in us completes when it is a success in terms of achieving the goals for which our clients start/conceptualize there projects and products.
                </p>

                <p className={`col-12 ml-auto mr-auto ${styles.outsource_description} text_color_grey`} >
                  End-to-end project based development services of 7CETCH can be the best way to create you next great idea from just an idea to a reality. We will develop it, nourish it and stay beside you until the full maturity of your idea to a stagring success.
                </p>

            </div>



            {/* Dedicated Resource (One or Many) */}
            <div className="ml-auto mr-auto col-12 col-lg-11 div_max_width" style={{  marginTop : '5%' }} >
               
                <div className={`col-12 text-left ml-auto mr-auto row`} style={{ margin : '3%' }}>
                   <div className="pt-3 m-0" >
                        <b className={`${styles.rounded_border_text}`} style={{ backgroundColor : '#00bcd4' }} > 3</b> 
                   </div>
                   <div className="col-9 text-left mt-2 " >
                      <b className={`${styles.font_heading} font-weight-light`} style={{ color : '#00bcd4' }} >   Dedicated Resource (One or Many) </b>
                   </div>
                </div>

                <p className={`col-12  ml-auto mr-auto ${styles.outsource_description} text_color_grey`}  >
                    If you have a killer technical team in place and your in-house needs are being taken care of properly, but you still want to grow further without moving to a new office and investing highly in hiring new developers, designers and software analysts, then 7CTECH dedicated resources service is the one for you.
                </p>

                <p className={`col-12 ml-auto mr-auto ${styles.outsource_description} text_color_grey`} >
                    You can pick and choose from a list of highly skilled resources for web development, web designing, mobile app development, Ionic framework, angularJS, NodeJS , PHP developers and Dot.Net developers as per your needs.
                </p>

                <p className={`col-12 ml-auto mr-auto ${styles.outsource_description} text_color_grey`} >
                    Our dedicated resources are skilled in Agile and team based development systems. We believe that our clients should not be compelled to hire expensive in-house developers and designers for expansion.
                </p>

                <p className={`col-12 ml-auto mr-auto ${styles.outsource_description} text_color_grey`} >
                    In the current world economy hiring every single technical and highly skilled resource in house is not a good idea probably. In today’s global village you can pick and chose from the big list of resources that can corporate and coordinate on the internet with help of Agile team management tools and accomplish great results.
                </p>

            </div>


            </div>

            

        </div>
    )  
  }
} 

export default OutSource;


