import React, { Component } from 'react';
import Layout from '../layout/layout'
import AnimatedText from '../components/aboutus/animated_text'
import Introduction from '../components/aboutus/introduction'
import BestServices from '../components/aboutus/best_services'
import BlueDiv from '../shared/blue_div/index'
import CoreValues from '../components/aboutus/core_values'
import Team from '../components/aboutus/our_team'
import Map from '../shared/map/map'
import {Helmet} from "react-helmet";
// import MetaTags from 'react-meta-tags'

class AboutUS extends React.Component {
  
  componentDidMount(){
    window.scrollTo(0 , 0)
  }
  

  render(){
    return (
        <Layout>
          <Helmet>
            <title>About Us - 7CTECH</title>
            <link rel="canonical" href="https://www.7ctech.com/about-us" />
            <meta property="og:locale" content="en_US" />
            <meta property="og:type" content="article" />
            <meta property="og:title" content="About Us - 7CTECH" />
            <meta property="og:url" content="https://www.7ctech.com/about-us" />
            <meta property="og:site_name" content="7CTECH" />
          </Helmet>
          <AnimatedText/>
          <Introduction/>
          <BestServices/>
          <BlueDiv/>
          <CoreValues/>
          <Team/>
          <div className="div_max_width ml-auto mr-auto mt-5 mb-5">
            <Map/>
          </div>
      </Layout>
      
    )
  }
} 

export default AboutUS;