
import React from 'react';
import styles from '../../styles/home/slider.module.css' 
import { Link } from 'react-router-dom'
import { slideInRight , slideInLeft , slideInDown} from 'react-animations'
import Radium, {StyleRoot} from 'radium';
import mobile_anim_slider1 from '../../images/slider_anim1.png'
import mobile_anim_slider2 from '../../images/slider_anim2.png'
import $ from 'jquery';

// const animation = {
//    slide_right: {
//     animation: '1s',
//     animationName: Radium.keyframes(slideInRight , 'slideInRight')
//   },

//   slide_left:{
//     animation: '1s',
//     animationName: Radium.keyframes(slideInLeft , 'slideInLeft')
//   },


//   slide_down: {
//    animation: '1s',
//    animationName: Radium.keyframes(slideInDown , 'slideInDown')
//   }


// }

// function Slider() {
//   return (
//      <StyleRoot>
//       <div className={`col-12  p-0 m-0 ml-auto mr-auto border ${styles.slider_div}`} style={{height: '95vh'}}>
         
//          <div className="col-12 ml-auto mr-auto div_max_width " style={{top: '15%'}}>
//          <div className={`col-12 ml-auto mr-auto row p-0 mb-5 `} style={{letterSpacing: '0.05em' , color: 'white'}}>
//             <p className="text text-center col-12 col-md-10 ml-auto mr-auto" style={animation.slide_down}>We strive to make sure that the service we deliver is not a complete high quality service, but also an innovative solution that can ensure return on investment for our clients and ourselves. </p>
//          </div>

//          <div className={` col-12 ml-auto mr-auto row p-0 pt-3`} style={{color: 'white'}}>
//             <div className="col-12 col-sm-6 ml-auto mr-auto pt-3" style={animation.slide_left}>
//              <h1 className="col-11 text-center mt-1" style={{fontFamily: 'Source Sans Pro', letterSpacing: '0.05em' , fontWeight: 'bold'}}>BUSINESS</h1>
//              <h1 className="col-11 text-center mt-1"  style={{fontFamily: 'Source Sans Pro', letterSpacing: '0.05em' , fontWeight: 'bold'}} >INFORMATION</h1>
//              <h1 className="col-11 text-center mt-1"  style={{fontFamily: 'Source Sans Pro', letterSpacing: '0.05em' , fontWeight: 'bold'}}>SYSTEMS</h1>
//             </div>

//             <div className="col-12 col-sm-6 ml-auto mr-auto pt-3 d-none d-sm-block" style={animation.slide_left}>
//             <h1 className="col-11 text-center mt-1" style={{fontFamily: 'Source Sans Pro', letterSpacing: '0.05em' , fontWeight: 'bold'}}>DRIVEN</h1>
//              <h1 className="col-11 text-center mt-1"  style={{fontFamily: 'Source Sans Pro', letterSpacing: '0.05em' , fontWeight: 'bold'}} >BY</h1>
//              <h1 className="col-11 text-center mt-1"  style={{fontFamily: 'Source Sans Pro', letterSpacing: '0.05em' , fontWeight: 'bold'}}>INNOVATION</h1>
//             </div>
//          </div>
//          <Link to="/service" >
//             <button className={`${styles.slider_btn}  border mt-4`} style={animation.slide_down} > OUR SERVICES </button>
//          </Link>
//       </div>
//       </div>
//       </StyleRoot>
//   );
// }
 
// export default Slider;


class Slider extends React.Component{

constructor(){
   super();
   this.state = {

   }
}

componentDidMount() {

   $(document).ready(function()
      {
         var pixelToMove = 5;
         $("#main_slider_image , #animated_slider_image1 , #animated_slider_image2 , animated_slider_image_mobile1 , animated_slider_image_mobile2 , #animated_div").mousemove(function(e)
            {
               var width = $(this).innerWidth();
               var height = $(this).innerHeight();
               var newValueX = (e.pageX / width) * pixelToMove;
               var newValueY = (e.pageY / height) *  pixelToMove;
               
                //  desktop
                $("#animated_slider_image1").css('top', (newValueY + 12) + '%');
                $("#animated_slider_image1").css('left', newValueX + '%');
                $("#animated_slider_image2").css('top', (newValueY + 15) + '%');
                $("#animated_slider_image2").css('right', newValueX + '%');
            
                // mobile   
                $("#animated_slider_image_mobile1").css('top', (newValueY + 12) + '%');
                $("#animated_slider_image_mobile1").css('left', newValueX + '%');
                $("#animated_slider_image_mobile2").css('top', (newValueY + 50) + '%');
                $("#animated_slider_image_mobile2").css('left', '-' + newValueX + '%');
             

            })
      });


}


render(){
   return(
      <div style={{ position: 'relative' , left: '0' ,  top: '0' }} >
         <img src="https://res.cloudinary.com/seven-ctech/image/upload/v1625586554/website-Images/slider_img_y1km3q.jpg" className="main_slider_image" style={{filter: 'greyscale'}} id="main_slider_image"/>
         
         {/* desktop images */}
         <img className="d-none d-md-block" src="https://res.cloudinary.com/seven-ctech/image/upload/v1625586214/website-Images/slider_1_bg_xjs6hg.png" id="animated_slider_image1"/>
         <img className="d-none d-md-block" src="https://res.cloudinary.com/seven-ctech/image/upload/v1625586214/website-Images/slider_2_bg_escefk.png" id="animated_slider_image2"/>
         {/* <img src="https://www.7ctech.com/wp-content/uploads/2017/07/Slider-CL01-Back-3.png" id="animated_slider_image3"/> */}

         {/* mobile images */}
         <img className="d-block d-md-none" src={mobile_anim_slider1} id="animated_slider_image_mobile1"/>
         <img className="d-block d-md-none" src={mobile_anim_slider2} id="animated_slider_image_mobile2" />

         <div className={`col-12  p-0 m-0 ml-auto mr-auto `} style={{ position : 'absolute' , top : '20%'  }} id="animated_div">
         
          <div className="col-12 ml-auto mr-auto div_max_width  " style={{top: '15%'}} >
          <div className={`col-12 ml-auto mr-auto row p-0 mb-5 `} style={{letterSpacing: '0.05em' , color: 'white'}} >
             <p className="text text-center col-12 col-md-10 ml-auto mr-auto" style={{fontFamily: 'calibri'}}>We strive to make sure that the service we deliver is not a complete high quality service, but also an innovative solution that can ensure return on investment for our clients and ourselves. </p>
          </div>

          <div className={` col-12 ml-auto mr-auto row p-0 pt-3`} style={{color: 'white'}} >
             <div className="col-12 col-sm-6 ml-auto mr-auto pt-3"  >
              <h1 className="col-11 text-center mt-1" style={{fontFamily: 'Source Sans Pro', letterSpacing: '0.07em' , fontWeight: 'bold'}}>BUSINESS</h1>
              <h1 className="col-11 text-center mt-1"  style={{fontFamily: 'Source Sans Pro', letterSpacing: '0.07em' , fontWeight: 'bold'}} >INFORMATION</h1>
              <h1 className="col-11 text-center mt-1"  style={{fontFamily: 'Source Sans Pro', letterSpacing: '0.07em' , fontWeight: 'bold'}}>SYSTEMS</h1>
             </div>

             <div className="col-12 col-sm-6 ml-auto mr-auto pt-3 d-none d-sm-block" >
             <h1 className="col-11 text-center mt-1 font-weight-light" style={{fontFamily: 'Source Sans Pro', letterSpacing: '0.07em'  }}>DRIVEN</h1>
              <h1 className="col-11 text-center mt-1 font-weight-light"  style={{fontFamily: 'Source Sans Pro', letterSpacing: '0.07em' }} >BY</h1>
              <h1 className="col-11 text-center mt-1 font-weight-light"  style={{fontFamily: 'Source Sans Pro', letterSpacing: '0.07em' }}>INNOVATION</h1>
             </div>
          </div>
          <Link to="/service" >
             <button className={`${styles.slider_btn}  border mt-4`}  > OUR SERVICES </button>
          </Link>
       </div>

      </div>

    </div>
    )
  }
}

export default Slider;