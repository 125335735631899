import styles from '../../styles/home/clients.module.css'
import { Link } from 'react-router-dom'
import {companyBlackLogo} from '../../constants/index'
function BlueDiv() {
 
    return(
        <div className="col-12 text-center ml-auto mr-auto pt-5 pb-5  p-0 m-0" style={{ backgroundColor : '#21a3df' , minHeight : '200px' }} >

            <div  className="col-12 text-center ml-auto mr-auto row div_max_width m-0 p-0 "  >

                <div className="col-12 col-md-4 mr-auto ml-auto text-center text-lg-left pt-4 pb-4"  >
                    <img className="text-center align-self-center" src="https://res.cloudinary.com/seven-ctech/image/upload/v1625587510/website-Images/7ctech-black-logo_eowtvl.png" />
                </div>

                <div className="col-12 col-md-4 mr-auto ml-auto text-center mt-2 pt-4 pb-4 "  >
                    <h6 className="text_color_white  align-self-center font-weight-light" style={{fontSize: '20px' }} > WE’D LOVE TO HEAR FROM YOU </h6>
                </div>

                <div className="col-12 col-md-4 mr-auto ml-auto text-center text-lg-right pb-4 pt-4 " >
                    <Link to="/contact-us" className="align-self-center" >
                        <button className={`col-7 col-md-8 col-lg-7 ${styles.blue_slide_btn}`} style={{height: '50px' , letterSpacing: '0.05em'}} > TALK TO US </button>
                    </Link>
                </div>

            </div>

        </div>
    
  )

}

export default BlueDiv