import React from 'react';
import styles from '../../styles/home/introduction.module.css'
 
const ChooseUS = [
    {
        title : 'CREDIBILITY' ,
        defination : '7CTECH team believes in credibility and reliability. Our professionals know the importance of building a true work relationship.'
    },

    {
        title : ' EFFICIENCY' ,
        defination : 'Efficiency is not a onetime demonstration of expertise. Our web company believes in constant and long term results.'
    },

    {
        title : 'ACCOMMODATE' ,
        defination : 'We believe business depends on its limitations and consumer behavior. This is why we pay attention to what our clients demand.'
    }
]


function Introduction() {
  return (
    <>
      <div className="col-12 text-left p-0 ml-auto mr-auto"  style={{ margin : '1% 0% 11% 0%' }}  >

         {/* what is 7ctech */}
         <div className="col-12  ml-auto mr-auto  div_max_width  row pt-md-0 p-md-4">
            
            <div className="col-12 p-0 col-lg-6 col-xl-6 text-lg-right m-0 " >
                <div  style={{ height : '65px' }} > </div>
                <img className={`${styles.intro_img}`} src="https://res.cloudinary.com/seven-ctech/image/upload/v1625586759/website-Images/intro_img_nzdqop.jpg" />
            </div>

            <div className={` ${ styles.intro_div } col-12  col-lg-6 col-xl-6 m-0 text-left text-md-center `}  >
                <h1 className="text_color_grey font-weight-light mb-4 mt-5 mt-md-0 text-left ls_3px" >  WHAT IS <h1 className="font-weight-bold text_color_blue d-inline-block lp_3px">  7CTECH </h1> </h1>  
                <div className={`${styles.first}`}><span className=" text_color_blue" > 7 </span>  CTECH comprises of experienced and creative designers and web developers. We focus on customer satisfaction to build a long term work relationship with our clients. As a web company, our prime focus is to offer flawless services over a longer period of time so that our customers can get maximum benefit. Our professionals realize the fact that customer satisfaction is the key to success. Web design, customer care features and innovative navigation attract the visitors to any website. Modern business depends mainly upon online platforms and business website is the most important tool to stay in touch with customers. 7CTECH offers excellent services at quite reasonable rates. We have kept in mind the limited resources of small businesses and this is why our web company offers such rates that are within the purchasing power of entrepreneurs. Our professionals believe in building credible relationship with clients. Our focus remains on satisfying the needs of our clients. We offer customized services to suit the specific industry and consumer trends. 7CTECH offers all web related services so there is no need to search for multiple service providers.</div>
            </div>

         </div>

      </div>

        {/* why choose us */}
        <div className="col-12 text-center  m-0 ml-auto mr-auto" style={{  backgroundColor : '#f0f3f2'  , padding : '2% 0px' , marginBottom : '0px'  }}  >
          
          <h1 className="col-12 div_max_width ml-auto mr-auto pt-2 text_color_grey font-weight-light text-left ls_3px"   >  WHY CHOOSE <h1 className="font-weight-bold text_color_blue d-inline-block ls_3px" >  7CTECH </h1> </h1>  

         <div className={`col-12  ml-auto mr-auto  row d-flex justify-content-center div_max_width p-0`} style={{ margin : '4% auto'  }} >
         

          {
              ChooseUS.map((val , ind) => {
                return (
                    <div className="row col-12 col-md-4 ml-auto mr-auto mt-4 mb-4 p-0  ">
                
                        <div className="col-8 col-xl-9 p-0 m-0 pr-3" >
                            <h6 className="text_color_grey text-left  font-weight-light mb-3 " style={{ letterSpacing : '1px' }} > { val.title } </h6>
                            <p className={`text_color_grey col-12 p-0 text-left  ${styles.text_design}`}  > { val.defination } </p>
                        </div>
                        
                        <div className="col-4 col-xl-3 p-0 m-0 " >
                            <i class="far fa-arrow-alt-circle-up text_color_blue" style={{ fontSize : '70px' }} ></i>
                        </div>
                </div>
                )
              })
          }
            
            
         </div>

      </div>

   </>   
  );
}
 
export default Introduction;


