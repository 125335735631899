import React from 'react';
import styles from  '../../../styles/footer.module.css'
import {withRouter} from 'react-router-dom'
 
class RecentPost extends React.Component {

    constructor(){
        super()
        this.state = {
            recent_post : []
        }
    }

    componentDidMount(){
        this.fetchRecentPost();
    }    

    fetchRecentPost(){
      fetch(`https://server-7ctech.herokuapp.com/api/blog/get_all`)  
      .then((response) => {
          return response.json();
      })
      .then((data) => {
          console.log('recent data' , data)
          this.setState({ recent_post : data.data }) 
      });
  }

    // viewBlog(data){
    //  window.scrollTo(0, 0);
    //  var a = document.createElement('a');
    //   a.href = `/blogs/${data.slug}/${data.id}`
    //  //this.props.history.push(`/blogs/${data.slug}/${data.id}` )
    //  if(window && window.location.pathname.includes('/blogs')){
    //      window.location.reload();
    //  }
    // }


render(){
    const { recent_post } = this.state;
  return (
      <div className="col-11 col-md-11 ml-auto mr-auto  text-left p-0 m-0">
          <h6 className="font-weight-bold text-left col-11"  style={{ color:'white' }} > RECENT POSTS </h6>
          <br/>
          <br/>
          

          {   recent_post.length > 0 &&
              recent_post.map((val , index) => {
                  return(
                      <div className="col-12 p-0 m-0" >
                        <div  className="col-12 p-0 m-0 row pb-2 pt-2">
                            <div className="col-4 text-center" >
                               { val.imgurl && <img  src={val.imgurl} className={`${styles.recent_post_img}`}  /> }
                            </div>
                            <div className="col-8" >
                               { val.title && <a href={`/blogs/${val.slug}/${val.id}`} style={{textDecoration: 'none'}}><p className={`${ styles.recent_post_title } pb-0 mb-0 `} style={{fontSize: '14px'}} > { val.title } </p></a> }
                               {val.date && <p className="pt-0 mt-0" style={{ fontSize : '12px' , color : 'rgb(33,163,196)' }} > { val.createdAt.substr(0 , 10) } </p> }
                            </div>
                        </div>
                        {
                            index < recent_post.length-1 &&
                            <hr className=" m-1 col-11 col-md-10 ml-auto mr-auto" style={{ backgroundColor : 'rgb(102, 102, 102)' }} />
                        }
                      </div>
                  )
              })
          }

      </div>
  );
 }
}



 
 
export default withRouter(RecentPost);