import React from 'react';
import styles from '../../styles/contactus/contact_form.module.css'
import  fetch  from 'isomorphic-fetch'
import Recaptcha from 'react-recaptcha';

 
// create a variable to store the component instance
let recaptchaInstance;

class Contactus extends React.Component {

    constructor(){
        super()
        this.state = {
            name : '' ,
            phone : '' ,
            email : '',
            message : '',
            show_responce_msg : false,
            responce_msg : ''
        }

        this.recaptchaload = this.recaptchaload.bind(this);
        this.verifyCallback =this.verifyCallback.bind(this);

    }    


    submitForm() {

        const { name , phone , email  , message } = this.state;
    
        console.log(`NAME ${name} , email ${email} phone ${phone}  , message ${message}`)
        
        if(name == '' || phone == '' || email == ''  || message == ''){
            this.setState({ show_responce_msg : true , responce_msg : 'Please fill out all fields' })
        }
        else if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email))
        {
            this.setState({ show_responce_msg : true , responce_msg : 'Please write valid email address' })
        }
        else if (!/^((\+92)|(0092))-{0,1}\d{3}-{0,1}\d{7}$|^\d{11}$|^\d{4}-\d{7}$/.test(phone))
        {
            this.setState({ show_responce_msg : true , responce_msg : 'Please enter valid mobile number' })
        }
    
        else {
            
            document.getElementById("submit_btn").disabled = true;
            
            fetch('https://server-7ctech.herokuapp.com/send' , {
                method : 'POST' ,    
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    name : name ,
                    email : email,
                    phone : phone ,
                    interest : '' ,
                    message : message
                  }) 
                })  
                .then((response) => {
                        return response.json();
                    })
                    .then((data) => {
                        if (data.success == false  ) {
                            console.log('error email')
                            document.getElementById("submit_btn").disabled = false;
                            this.setState({ show_responce_msg : true , responce_msg : data.message  })
                        }
                        else {
                            console.log('success email')
                            document.getElementById("submit_btn").disabled = false;
                            this.setState({ 
                                show_responce_msg : true , 
                                responce_msg : data.message , 
                                name : '' , 
                                phone : '' ,
                                email : '' ,
                                interest : '' ,
                                message : '' ,
                            })
                        }
                    }).catch((err) => {
                        
                        document.getElementById("submit_btn").disabled = false;
                        this.setState({ 
                            show_responce_msg : true , 
                            responce_msg : "Some thing went wrong in submiting form. Please try later" 
                        })
                    })
                }
        }  
        
        recaptchaload(){
            console.log('captcha loaded sucessfully');
        }
    
        verifyCallback(response){
            console.log(' recaptcha responce' , response)
            if(response){
              this.setState({
                recaptcha_verified : true
              })
            }
          }
           
  
render() {
    return (
        <>
           <div className={`col-12 p-0 m-0`}   >
              
              <div className="col-12 col-lg-12 ml-auto mr-auto p-0 m-0"  >
                  
                        <div className="input-group col-md-12">
                            <input className={`form-control shadow-none`} style={{ backgroundColor:'white' , color:'grey' , height : '45px' , borderRadius : '0px'  }} placeholder="Name * " value={this.state.name} onChange={ (e) => this.setState({ name : e.target.value }) } />
                        </div>
                    

                        <div className="input-group col-md-12 mt-4">
                            <input className={`form-control  border-right-0  shadow-none border`} style={{ backgroundColor:'white' , color:'grey' , height : '45px' , borderRadius : '0px' }}  placeholder="Email * " value={this.state.email}  onChange={ (e) => this.setState({ email : e.target.value }) }/>
                            <span className="input-group-append">
                                <button className={`btn border border-left-0 shadow-none show_aero`} type="button" style={{ backgroundColor:'white' , height : '45px' , borderRadius : '0px' }} >
                                    <i className="far fa-envelope" style={{ color:'gray' }} ></i>
                                </button>
                            </span>
                        </div>

                        <div className="input-group col-md-12 mt-4">
                            <input className={`form-control  border-right-0  shadow-none border`} style={{ backgroundColor:'white' , color:'grey' , height : '45px' , borderRadius : '0px' }} placeholder="Your Phone * " value={this.state.phone} onChange={ (e) => this.setState({ phone : e.target.value }) } />
                            <span className="input-group-append">
                                <button className={`btn border border-left-0 shadow-none show_aero`} type="button" style={{ backgroundColor:'white' , height : '45px' , borderRadius : '0px' }} >
                                    <i className="fas fa-phone" style={{ color:'gray' }} ></i>
                                </button>
                            </span>
                        </div>


                        <div className="input-group col-md-12 mt-4">
                            <textarea className={`form-control shadow-none border`} style={{ backgroundColor:'white' , color:'grey' , minHeight:'200px'  , height : '45px' , borderRadius : '0px' }} placeholder="Message * " value={this.state.message} onChange={ (e) => this.setState({ message : e.target.value }) } />
                        </div>

                        {/* <div className="col-12 p-3 text-center" style={{  minHeight : '100px'}}  > 
                            <Recaptcha 
                                ref={e => recaptchaInstance = e}
                                sitekey="6LeZh2QaAAAAAJxh1bq4e6XUtVmr8UiWYjLy7ynP"
                                render="explicit"
                                onloadCallback={this.recaptchaload}
                                verifyCallback={this.verifyCallback}
                            />
                        </div> */}

                        {/* testing key
                        sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI" */}
                    
                        <div className="input-group col-md-12 mt-4">
                            <button className={`btn  col-12 shadow-none ${styles.contact_us_btn}`} onClick={() => { this.submitForm() }  }  id="submit_btn" >  SUBMIT  </button>
                        </div>

                        {
                            this.state.show_responce_msg &&
                            <p className="p-2 m-1 m-3 text-left" style={{ border : 'solid 2px #ffb900' , color: '#89a9b5' , minHeight : '100px' , fontSize : '15px'}}  > { this.state.responce_msg } </p>
                        }

                </div>

              </div>


        </>
       );
    }
}
 
export default Contactus;


