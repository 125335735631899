import React, { Component } from 'react';
import AdminDashboardComp from '../components/adminLayout/index'

class AdminDashboard extends React.Component {
  
  render(){
    return (
        <>
            <AdminDashboardComp />
        </>
      
    )
  }
} 

export default AdminDashboard;