import React from 'react';
 
function Introduction() {
  return (
      <div className="col-11 text-left p-0 m-0 ml-auto mr-auto">
          <img src="https://res.cloudinary.com/seven-ctech/image/upload/v1625587510/website-Images/7ctech-black-logo_eowtvl.png" alt="footer_logo" />
          <br/>
          <p className="mt-2 text-left" style={{ color:'#89a9b5' , fontSize: '15px' }} > Our prime focus is to offer flawless services over a longer period of time so that our customers can get maximum benefit. Our professionals realize the fact that customer satisfaction is the key to success. </p>
      </div>
  );
}
 
export default Introduction;