import React, { Component } from 'react';
import Layout from '../components/adminLayout/index'
import AddBlogComp from '../components/addBlog/index'

class AddBlogs extends React.Component {
  
  componentDidMount(){
    window.scrollTo(0 , 0)
  }
  
  render(){
    return (
        <Layout>
           <AddBlogComp />
        </Layout>
      
    )
  }
} 

export default AddBlogs;