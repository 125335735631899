import styles from '../../styles/aboutus/core_values.module.css'


const core_values = [
    {
        title : 'Client Satisfaction First' ,
        description : 'What is the best long term investment? 7CTECH believes that client satisfaction is the best form of long term investment.' ,
        url : '/#'
    },
    {
        title : 'Innovation and Intellect' ,
        description : 'Technology is advancing day by day and it requires both, innovation and intellect to compete in the global market.' ,
        url : '/#'
    },
    {
        title : 'Core Values of Integrity' ,
        description : 'Our professionals realize the fact that business ethics and moral values determine the goodwill of any professional organization.' ,
        url : '/#'
    }
]



function CoreValues() {
    return ( 
        <div className={`col-12   p-0 m-0 ml-auto mr-auto  ${styles.about_us_core_values_div}`}>
       
            <div  className={`col-12 p-0 m-0 ml-auto mr-auto d-flex div_max_width row`} >

                    <div className={`p-0 col-12 ml-auto mr-auto `}   >
                            
                            <h4 className={`text-left col-12 ml-auto mr-auto font-weight-bold ${styles.about_core_value_heading}`} > OUR CORE VALUES </h4>
                
                            <div className="col-12  p-0 m-0 ml-auto mr-auto mt-5"  >

                                {  core_values.map((val , ind) => {
                                    return(
                                    <div className={`col-12 col-md-7 col-lg-6  mt-5 mb-5  text-left show_pointer row  p-0 ${styles.about_values_detail_div }`} >  
                                        <div className="col-9 col-md-10">
                                            <h6 className={`text-left   ${ styles.about_values_detail_title } m-3 font-weight-bold`} style={{fontSize: '16px' , letterSpacing: '0.05em'}}> {` ${val.title.toUpperCase()} `} </h6>
                                            <p className="text-left text_color_grey  ml-auto mr-auto pl-3" style={{ fontSize : '15px' , letterSpacing: '0.05em', color: 'rgb(112,129,141)' }} > {val.description} </p>
                                        </div>
                                        <div className="col-3 col-md-2 d-flex"  >
                                            <i class={`fas fa-check-circle align-self-center ${styles.about_values_icon} `}></i>
                                        </div>
                                        
                                    </div>
                                    )
                                })
                                }
                                
                            </div>

                        </div>
                        <div className="col-12 col-md-6 col-xl-5">

                        </div>

            </div>
                
        </div>
    );
  }
   
  export default CoreValues;