import React, { Component } from 'react';
import Layout from '../components/adminLayout/index'
import ViewBlogComp from '../components/viewBlog/index'

class ViewBlog extends React.Component {
  
  componentDidMount(){
    window.scrollTo(0 , 0)
  }
  

  render(){
    return (
        <Layout>
           <ViewBlogComp />
        </Layout>
      
    )
  }
} 

export default ViewBlog;