import React, { Component } from 'react';
import fetch from 'isomorphic-fetch'
import {withRouter} from 'react-router-dom'

class ViewBlog extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            blogArr: [],
            msg: '',
            isLoading: false
        }
    }

    componentDidMount() {
        this.getAllBlogs();
    }

    updateBlog(id){
        // console.log('id ', id)
        this.props.history.push('/dashboard/update/'+id)
    }

    getAllBlogs() {
        this.setState({ isLoading: true })
        fetch('https://server-7ctech.herokuapp.com/api/blog/get_all', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (data.success == false) {
                    // console.log('error email')
                    this.setState({ msg: data.info })
                }
                else {
                    // console.log('success fetch')
                    this.setState({
                        blogArr: data.data,
                        isLoading: false
                    })
                }
            }).catch((err) => {
                console.log('Error: ', err)
            })
    }

    deleteBlog(id, i) {
        fetch('https://server-7ctech.herokuapp.com/api/blog/delete/' + id, {
            method: 'DELETE',
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (data.success == true) {
                    // let arr = this.state.blogArr
                    // let a = arr.splice(i, 1)
                    // this.setState({blogArr : this.state.blogArr.splice(i , 1)})
                    alert('Deleted Sucessfully')
                }
                else {
                    this.setState({
                        msg: data.info
                    })
                }
            }).catch((err) => {
                console.log('Error: ', err)
            })
    }

    render() {

        return (
            <div className="">
                <h6 className="text-center mt-3 mb-3">View Blogs</h6>
                {
                    this.state.isLoading &&
                    <div style={{ textAlign: 'center', marginTop: '20%' }}>
                        <div className="spinner-border text-center" style={{ width: '3rem', height: '3rem' }} role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                        <p className="mt-1">Fetching Data Please Wait...</p>
                    </div>
                }

                {
                    !this.state.isLoading &&
                    <div className="col-12 row p-3 ml-auto mr-auto">
                        {
                            this.state.blogArr.map((val, i) => {
                                return (
                                    <div className="col-11 p-2 border ml-auto mr-auto mt-3 row" key={i} >
                                        <div className="col-3">
                                            <img style={{ width: '100%', height: '200px' }} src={val.imgurl} />
                                        </div>
                                        <div className="col-8" style={{fontSize: '12px'}}>
                                            <p className="mt-0 mb-0"><b>TITLE : </b>{val.title}</p>
                                            <p className="mt-0 mb-0"><b>AUTHOR : </b>{val.createdby}</p>
                                            <p className="mt-0 mb-0"><b>STATUS :</b>{val.status}</p>
                                            <hr className="mt-1 mb-1" />
                                            <p className="mt-0 mb-0"><b>META TITLE : </b>{val.metatitle}</p>
                                            <p className="mt-0 mb-0"><b>META DESC :</b>{val.metadescription}</p>
                                            <hr className="mt-1 mb-1" />
                                            <p><b>DESCRIPTION :</b></p>
                                            <div dangerouslySetInnerHTML={{ __html: val.content }} ></div>
                                            <p className="text-center">
                                                <a className="btn btn-primary mr-3" onClick={()=> {this.updateBlog(val.id)}}>Update</a>
                                                <a className="btn btn-danger" onClick={() => { this.deleteBlog(val.id, i) }}>Delete</a></p>
                                        </div>

                                    </div>
                                )
                            })
                        }
                    </div>
                }
            </div>
        );
    }

}

export default withRouter(ViewBlog);