import styles from '../../styles/aboutus/best_services.module.css';

function Introduction() {
  return ( 
        <div className={`col-12 ml-auto mr-auto div_max_width`} style={{ margin : '6% 0%' }}>
            
            <h1  className="text-center mt-4  "  style={{fontFamily: 'times new roman' , color: '#3c3950' , letterSpacing: '0.05em'}}> WHAT IS <h1 className="text_color_blue d-inline-block font-weight-bold" style={{fontFamily: 'Montserrat' , letterSpacing: '0.05em' }} > 7CTECH </h1> </h1>
            <h6 className="text_color_grey mt-3 text-center font-weight-light col-12 ml-auto mr-auto" style={{fontFamily: 'times new roman' , color: '#3c3950' , letterSpacing: '0.05em'}} > EXPERIENCED AND CREATIVE DESIGNERS AND WEB DEVELOPERS. </h6>

            <div className={`col-12 mt-5 pt-5 mb-5 ml-auto mr-auto row p-0 pr-2 `} >
                <div className="col-12 col-md-6 col-xl-6 ml-auto mr-auto p-2 border pt-2 pb-2 " style={{ background : '#333144' }} >
                    <h5 className="text-left mt-5 pt-1 col-11 ml-auto mr-auto text_color_white font-weight-bold" style={{letterSpacing: '0.05em'}} > MISSION STATEMENT </h5>
                    <h6 className="text-left mt-3 col-11 mb-0 ml-auto mr-auto font-weight-light" style={{color: '#bcc6cd' , letterSpacing: '0.05em' , fontSize: '24px'}}> A close look at “Our Mission” reveals the importance of long term success of the business of our clients. We have an indirect approach…. </h6>
                    <div className="text-right p-0 m-0 mt-0" style={{maxHeight: '80px'}}>
                    <p className="text text-right pr-3 pr-md-5 mt-0" style={{color: '#a3e7f0' , fontSize: '110px' , fontFamily: 'Source Sans Pro'}}>”</p>
                    </div>
                </div>

                <div className={`col-12 col-md-6 col-xl-6 ml-auto mr-auto  pt-2 pb-2 pl-4`}  >
                    <hr className="col-9  ml-auto mr-auto" />
                    <p style={{ fontSize : '16px' , color: '#5f727f'  }} className="mt-3 col-12 ml-auto mr-auto font-weight-normal" > 7CTECH comprises of experienced and creative designers and web developers. We focus on customer satisfaction to build a long term work relationship with our clients. As a technology company, our prime focus is to offer flawless services over a longer period of time so that our customers can get maximum benefit. Our team of professionals realize the fact that customer satisfaction is the key to success.
                        7CTECH offers excellent services at quite reasonable rates. We have kept in mind the limited resources of small businesses and this is why our company offers such rates that are within the purchasing power of entrepreneurs. Our professionals believe in building credible relationship with clients.
                    </p>
                    <hr className="col-9 ml-auto mr-auto"/>
                </div>


            </div>

        </div>
     
  );
}
 
export default Introduction;