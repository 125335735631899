import React from 'react';
import styles from '../../styles/home/service.module.css'
import { Link , withRouter} from 'react-router-dom'

 
class Service extends React.Component {


serviceDetail(index){
  this.props.history.push("/service", { index : index })
}


 render(){
    return (
    
        <div className={`col-12 p-0 m-0 ml-auto mr-auto  ${styles.main_service_div}`} >
    
            <div className="col-12 col-lg-12 text-center div_max_width  m-0 ml-auto mr-auto " style={{  paddingBottom : '8%'  }}  >
    
                <div className="ml-auto mr-auto text-center col-12 " style={{ padding : '5% 0px 2% 0px' }} >
                    <i className={`fas fa-bars text_color_yellow ${ styles.our_service_logo }`}  ></i>
                </div>
              
               <h1 className="col-12 text_color_white font-weight-light ls_3px" style={{ fontFamily : 'Montserrat' }}  >  OUR <h1 className="font-weight-bold d-inline-block ls_3px" style={{ fontFamily : 'Montserrat' }} > SERVICES </h1> </h1>  
    
                <div className="col-12 p-0 ml-auto mr-auto  d-flex justify-content-center row  " style={{  marginTop : '6%' , }} >
                
                    <div className="col-11 col-md-5 col-lg-3 p-0 ml-auto mr-auto show_pointer" onClick={()=>{ this.serviceDetail(0) }} >
                            <p  className="text-center"> <i class="fas fa-paint-brush text_color_yellow"></i> </p>
                            <h6 className={`text_color_white text-center ml-3 ${styles.text_title_design}`}  > DESIGN & DEVELOPMENT </h6>
                            <p className={`text_color_white col-12  text-center ml-auto mr-auto text-justify font-weight-light ${styles.text_design}`}> Our dedicated team is Working on WordPress, AngularJS, NodeJS, PSDs and more..  </p>                       
                    </div>
    
                    <div className="col-11 col-md-5 col-lg-3 p-0 ml-auto mr-auto show_pointer" onClick={()=>{ this.serviceDetail(1) }}>
                            <p  className="text-center"> <i class="fas fa-cart-plus text_color_yellow"></i> </p>
                            <h6 className={`text_color_white text-center ml-3 ${styles.text_title_design}`} > E-COMMERCE WEBSITE </h6>
                            <p className="text_color_white col-12  text-center ml-auto mr-auto text-justify font-weight-light" style={{ fontSize : '16px'}} > We Offer Customized E-Commerce services along with development in magento, WooCommerce..  </p>                       
                    </div>
    
                    <div className="col-11 col-md-5 col-lg-3 p-0 ml-auto mr-auto show_pointer" onClick={()=>{ this.serviceDetail(3) }}>
                            <p  className="text-center"> <i class="fas fa-mobile-alt text_color_yellow"></i> </p>
                            <h6 className={`text_color_white text-center ml-3 ${styles.text_title_design}`}  > MOBILE APP DEVELOPMENT </h6>
                            <p className="text_color_white col-12  text-center ml-auto mr-auto text-justify font-weight-light" style={{ fontSize : '16px'  }} > Mastering the art of creating mobile applications that offer services on responsive and native development..  </p>                       
                    </div>
    
                    <div className="col-11 col-md-5 col-lg-3 p-0 ml-auto mr-auto show_pointer" onClick={()=>{ this.serviceDetail(4) }}>
                            <p  className="text-center"> <i class="fas fa-search-plus text_color_yellow"></i> </p>
                            <h6 className={`text_color_white text-center ml-3 ${styles.text_title_design}`} > DATA SCRAPING </h6>
                            <p className="text_color_white col-12  text-center ml-auto mr-auto text-justify font-weight-light" style={{ fontSize : '16px' }} > Data mining systems, text mining solutions, big data Analysis, API Integrations and more..   </p>                       
                    </div>
                

                <div className="col-12 text-center" style={{ paddingTop : '7%'  }} >
                    <Link to="/service" >
                            <button className={`${styles.service_btn} `} > VIEW ALL </button>
                    </Link>
                </div>

            </div>

            </div>
            
            </div>
    
       
      );
    }     
 }
 
export default withRouter(Service);


