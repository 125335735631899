import React, { Component } from 'react';
import Layout from '../components/adminLayout/index'
import AddUserComp from '../components/addUser/index'

class AddUser extends React.Component {
  
  componentDidMount(){
    window.scrollTo(0 , 0)
  }
  

  render(){
    return (
        <Layout>
           <AddUserComp />
        </Layout>
      
    )
  }
} 

export default AddUser;