import React, { Component } from 'react';
import styles from  '../../styles/blogdetail/blogdetail.module.css'
import RecentPost from '../../shared/footer/components/recent_post'
import {withRouter} from 'react-router-dom'
// import MetaTags from 'react-meta-tags'
import  fetch  from 'isomorphic-fetch'
import {Helmet} from "react-helmet";


class BlogsDetail extends React.Component {

    constructor(props) {
        super(props);
        
        this.state = {
          blog_detail : [],
          recent_post : []
        }
      }
    
      componentDidMount(){
        window.scrollTo(0, 0);
        this.fetchRecentPost();
        this.fetchBlogDetail();
      }

      fetchRecentPost(){
        fetch(`https://server-7ctech.herokuapp.com/api/blog/get_all`)  
        .then((response) => {
              return response.json();
          })
          .then((data) => {
              // console.log('recent data' , data)
              this.setState({ recent_post : data.data }) 
          });
      }
  
      fetchBlogDetail() {
        var url = window.location.pathname;
        var id = url.substring(url.lastIndexOf('/') + 1 , url.length);

        fetch(`https://server-7ctech.herokuapp.com/api/blog/find_by_id/ `+id)  
        .then((response) => {
              return response.json();
          })
          .then((data) => {
              // console.log('search id' , data)
              this.setState({ blog_detail : data.data }) 
          });

          console.log('blog' , this.state.blog_detail)
      }

     viewBlog(data){
      var a = document.createElement('a');
      a.href = `/blogs/${data.slug}/${data.id}`
      // this.props.history.push(`/blogs/${data.slug}/${data.id}` )
      this.fetchBlogDetail();
     }


    render(){
     const { blog_detail , recent_post } = this.state;
    //  console.log('render' , blog_detail)
    //  console.log('length' , blog_detail.length)
      return (
          <>
            {/* { 
              blog_detail &&
              blog_detail.length > 0  ?  */}
            
            <div className="col-12 m-0 p-0">
             
              <Helmet>
                {/* {  blog_detail.length > 0  &&
                  blog_detail.title && */}
                  <title>{`${blog_detail.title} - 7CTECH`}</title> 
                {/* } */}
                  {/* <link rel="canonical" href="https://www.7ctech.com/blogs" /> */}
                  <meta name="description" content={blog_detail.metadescription} />
                  <meta property="og:title" content={blog_detail.metatitle} />
                  <meta property="og:description" content={blog_detail.metadescription} />

                  {/* <meta property="og:locale" content="en_US" />
                  <meta property="og:type" content="article" />
                  <meta property="og:url" content="https://www.7ctech.com/blogs" />
                  <meta property="og:site_name" content="7CTECH" /> */}
              </Helmet>
         
                    <div className="col-12 p-3 breadcrumb_div" style={{backgroundColor: 'rgb(27,28,45)' }}>
                      {  
                        blog_detail.title &&
                          <h3 className="text text-center pt-5" style={{color: 'white'}}>{blog_detail.title}</h3>
                      }
                      <nav aria-label="breadcrumb ml-auto mr-auto text text-center">
                        <ol className="breadcrumb justify-content-center" style={{ backgroundColor : 'transparent' }}>
                            <li className="breadcrumb-item"><a href="/">Home</a></li>
                            <li className="breadcrumb-item text_color_white" aria-current="page"> <a href="/blogs">Blogs</a></li>

                            {  
                              blog_detail.title &&
                              <li className="breadcrumb-item active text_color_white text-centers" aria-current="page"> { blog_detail.title }  </li>
                            }

                            
                        </ol>
                     </nav>
                    </div>
                

                <div className="col-12 border ml-auto mr-auto">

                    <div className="col-12 ml-auto mr-auto row div_max_width" style={{paddingTop: '5%' , overflow: 'hidden'}}>
                        <div className="col-12 col-lg-8 ml-auto mr-auto p-0">
                            <img className="mt-1 ml-auto mr-auto  p-3"  width="100%" style={{maxHeight: '400px'}} src={blog_detail.imgurl}/>
                            <p className="ml-2 p-2 pt-0 " style={{fontSize: '14px'}}>By <a  href="/" ><span> {blog_detail.createdby} </span></a> | { blog_detail.createdAt && `${blog_detail.createdAt.substr(0 , 10)}`}<i class="fas fa-heart mr-2 mt-1" style={{float: 'right' , fontSize: '15px'}}> {3}</i></p>
                           {
                            blog_detail.content &&
                            <div className={`p-3 danger_innerhtml `} style={{textAlign: 'justify'}}  dangerouslySetInnerHTML={{__html:  blog_detail.content }} />
                           }
                         </div>

                        
                      <div className="col-12 col-lg-3  ml-auto mr-auto p-2 mt-0 pt-0  pb-0 mb-0">
                            <b className="pt-0 mt-0">RECENT POSTS</b>
                            <div className="p-0 mt-2 " style={{backgroundColor: 'rgb(244,246,247)'}}>
                            <table class="table">
                                <tbody>
                                {   recent_post.length > 0 &&
                                    recent_post.map((val , ind ) => {
                                      return(  
                                          <tr className={`${styles.recent_post}`} key={ind}><a href={`/blogs/${val.slug}/${val.id}`} style={{textDecoration: 'none' , color : 'rgb(129,145,155)'}}>
                                              <td><i className="far fa-file-alt ml-3 mt-2" style={{fontSize: '20px' , color: 'rgb(182,198,201)'}}></i></td>
                                              { val.title && <td  style={{color: 'rgb(129,145,155)' , fontSize: '14px'}}>{val.title}</td>}</a>
                                          </tr>
                                      )
                                  })
                                }
                                </tbody>
                            </table>
                        
                            </div>
                       </div>

                            
                    </div>

                </div>
            </div>
        {/* //    :
       
        //      <div className="text-center" style={{ marginTop : '10%' }} >

        //       <div className="spinner-border" style={{ width: '3rem' , height: '3rem'}} role="status">
        //           <span className="sr-only">Loading...</span>
        //       </div>
        //       <p className="text-center mt-5" > Loading Please Wait </p>

        //  </div>   
        
        //  }  */}
       </> 
      )

    }
}


export default withRouter(BlogsDetail)