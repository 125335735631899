import React, { Component } from 'react';
import AdminComp from '../components/admin/index'

class Admin extends React.Component {
  
  render(){
    return (
        <>
            <AdminComp />
        </>
      
    )
  }
} 

export default Admin;