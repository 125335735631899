import React, { Component } from 'react';
import { withRouter , Link } from 'react-router-dom'

class AdminSideBar extends React.Component {
  
    constructor(props){
        super(props)
        this.state={
            detail : {}
        }
    }

    componentDidMount() {
        if(localStorage.getItem('userDetail') != null){
            this.setState({detail : JSON.parse(localStorage.getItem('userDetail'))})
        }
        else{
            this.props.history.push('/admin')
        }
    }
        
render(){

        return(
            <div className=" p-0" style={{backgroundColor: 'rgb(34,46,50)' , minHeight: '95vh' , height: 'auto', maxWidth: '240px'}}>
            <div className="col-12 ml-auto mr-auto pt-4 p-0 m-0" style={{color: 'white'}}>

                {
                this.state.detail.type_id == 1 &&            
                <Link to="/dashboard/new_user" style={{textDecoration: 'none'}}>
                <p className="mt-2 mb-1 ml-3" style={{fontSize: '16px' , cursor: 'pointer'  , color: 'white'}}>Create New User</p>
                </Link>
                }
                
                {
                 this.state.detail.type_id == 1 &&
                <Link to="/dashboard/all_users" style={{textDecoration: 'none'}}>
                <p className="mt-2 mb-1 ml-3" style={{fontSize: '16px' , cursor: 'pointer'  , color: 'white'}}>View All User</p>
                </Link>}
                <Link to="/dashboard/add-blog" style={{textDecoration: 'none'}}>
                <p className="mt-2 mb-1 ml-3" style={{fontSize: '16px' , cursor: 'pointer'  , color: 'white'}}>Add New Blog</p>
                </Link>
             
                <Link to="/dashboard/blog" style={{textDecoration: 'none'}}>
                <p className="mt-2 mb-1 ml-3" style={{fontSize: '16px' , cursor: 'pointer'  , color: 'white'}}>View All Blog</p>
                </Link>
            </div>
        </div>
        );
    }

    }

export default withRouter(AdminSideBar);