import React from "react";
import styles from '../../../styles/header/desktop_header.module.css'
import Tabs from '../desktop/tabs.js'
import { Link } from 'react-router-dom'
import {  navBarLogo } from '../../../constants/index'




class HeaderDesktop extends React.Component {

  constructor(){
    super();
    this.state = {
       show_bottom_scroll : false ,
    } 
  } 


  scrollToTopButton() {
    window.scrollTo({top: 0, behavior: 'smooth'})
  }

  // scrollfunction(){
  //   if(document.body.scrollTop > 10 || document.documentElement.scrollTop > 10 ){
  //     // this.setState({scroll : true})
  //     document.getElementById("scroll_btn").style.bottom = "10%"
  //   }
  // }


  // componentDidUpdate(){
  //   window.onscroll= this.scrollfunction();
  //   console.log('chala')
  // }

  componentDidMount(){
    var header = document.getElementById('header_div')
     if(header != undefined) {
      window.onscroll = (e) => {
        if(window.scrollY) {
            if(window.pageYOffset > 50) {
                header.style.opacity = '0.9';
                this.setState({ show_bottom_scroll : true })
              } else {
                header.style.opacity = '1';
                this.setState({ show_bottom_scroll : false })
                
            }
         }
       }
     }
  }
  
  render(){
    return (
      <div>
      <div  id="header_div"  className={`${styles.desktop_main_div} col-12 d-none d-md-flex fixed-top m-0 p-0  ml-auto mr-auto`} >
          <div className={`${styles.desktop_main_div} col-12 d-none d-md-flex p-0 row fixed-top div_max_width ml-auto mr-auto `} >
                
                <div className={` ${styles.desktop_logo_div}  col-md-12 col-lg-3 col-xl-4 text-left  p-0 m-0 d-inline-block`}>
                    <Link to="/" >
                        <img className={`${styles.desktop_logo} pt-auto pb-auto m-md-auto ml-lg-0 `}  src={ navBarLogo } alt="logo" />
                    </Link>
                </div>

                <div className={`${styles.desktop_tabs_div} col-md-12 col-lg-9 col-xl-8 p-0`} >
                    <Tabs/>
                </div>

          </div>
          
      </div>

        { this.state.show_bottom_scroll == true &&
          <div id="scroll_btn" onClick={ ()=>{ this.scrollToTopButton() } } className={`show_pointer p-2 ${styles.scroll_btn}`} style={{bottom: '5%' , backgroundColor: 'rgb(123,124,128)' , height: '50px' , width: '50px' , right: '2%' , zIndex: '3000' , position: 'fixed' , color: 'white'}}>
            <p className="text-center mt-1"><i class="fa fa-angle-up" style={{fontSize: '24px'}}  ></i></p>
        </div>
        }
     </div>
    )
  }
  
}

export default HeaderDesktop;
