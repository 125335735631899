import React from 'react';
import styles from '../../styles/home/clients.module.css'
import BlueDiv from '../../shared/blue_div/index'

import { slideInUp } from 'react-animations'
import Radium, {StyleRoot} from 'radium';

const animation = {

  slide_up : {
    animation: ' 2s',
    animationName: Radium.keyframes(slideInUp , 'slideInUp')
  }

}

 
const Client_data = [
    {
        position : 'CEO – Percept Media',
        name : 'DAWAR ALI KHAN' ,
        defination : 'Working together with 7CTECH to launch a website for my new company, was a very good experience as it worked out really well, both  aesthetically and functionally. I wanted a modern website with added functions & uses and 7CTECH was able to deliver just that. I found them efficient and professional in their mannerism.',
        sex : 'm'
    },

    {
        position : 'Marketing Manager – Eventage.',
        name : 'FARYAL IFTIKHAR' ,
        defination : 'It was great working with 7CTECH’s team as they are very accommodating, professional and expert in their field. The best thing about Ubaid was good communication and ability to understand exactly what we wanted. Will not hesitate to work with them again on future projects.',
        sex : 'f'
    },

    {
        position : 'Director – Umbrella Network.',
        name : 'ALISTER DSOUZA' ,
        defination : 'The Umbrella Network was desperately seeking a highly professional web development team to design its website. 7CTECH despite finding them from a random web search we were glad that we made the choice. Extremely talented and highly professional, Both Danish and Ubaid provided a solution which was extremely cost effective and stylish. Keep It Up Guys!',
        sex : 'm'
    }
]


function Clients() {
  return (
   
        <StyleRoot>
        <div className="col-12 text-center m-0 ml-auto mr-auto pr-0 pl-0" style={{  padding : '2% 0%' , paddingTop : '5%' , marginBottom : '0px'  }}  >
                
                <h1 className="col-12  pt-4 font-weight-light ls_3px">  OUR CLIENT <h1 className="font-weight-bold text_color_blue d-inline-block ls_3px" >  ABOUT US </h1> </h1>  

                <div className="col-12 col-lg-12 pr-0 pl-0 ml-auto mr-auto d-flex justify-content-center row div_max_width" style={{ marginTop: '2%' , paddingBottom : '50px' }} >
                
                {
                    Client_data.map((val , ind) => {
                        return (
                            <div className={`row col-12 col-md-4  ml-auto mr-auto mt-2 mb-2  mt-md-5 mb-md-5 p-0 `} style={animation.slide_up} >
                        
                                <div className="col-12 p-0 m-0 text-center" style={{position: 'relative' }} >
                                    {   val.sex == 'm' ?
                                        <img className={`${styles.client_div_img}`} src="https://res.cloudinary.com/seven-ctech/image/upload/v1625587408/website-Images/user-img_caind4.png"  /> 
                                        :
                                        <img className={`${styles.client_div_img}`} src="https://res.cloudinary.com/seven-ctech/image/upload/v1625587408/website-Images/user-img_caind4.png" /> 
                                    }
                                    <p className={`col-12 ml-auto mr-auto text-center mt-4  ${styles.text_design}`} style={{ paddingBottom: '180px'}} > { val.defination } </p>
                                    <div className={`${styles.client_div_data}`} >
                                      <p className=" text_color_blue mb-0 font-weight-bold" style={{ fontWeight : '2px' , fontSize: '16px' , letterSpacing: '0.05em'}}> { val.name } </p>
                                     <p className=" text_color_grey d-inline-block" style={{fontSize: '12px'}}> { val.position } </p>
                                     <p className="text text-center mt-0" style={{color: '#a3e7f0' , fontSize: '50px' , fontFamily: 'Source Sans Pro'}}>”</p>
                                    </div>

                                </div>
                        </div>
                        )
                    })
                }
                    
                    
                </div>





            </div>

            <BlueDiv/>

           
        </StyleRoot>
      

 
  );
}
 
export default Clients;


