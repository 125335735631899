
function Map() { 
    return(
        <div className="col-12 text-center p-0 ml-auto mr-auto"  >

            <div  className="col-12 text-center ml-auto mr-auto p-0 "  >
                 <iframe className="col-12 ml-auto mr-auto map_size" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14479.779354732254!2d67.0793715!3d24.8657335!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x84cf59a8354bb238!2s7CTECH%20-%20Your%20Perfect%20Business%20Solution!5e0!3m2!1sen!2s!4v1613739160332!5m2!1sen!2s" frameborder="0" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
            </div>

        </div>
      )
   }

export default Map;