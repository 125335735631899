import React, { Component } from 'react';
import {BrowserRouter as Router , Switch , Route } from 'react-router-dom'; 
import Home from './screens/Home'
import AboutUS from './screens/AboutUS'
import OutSource from './screens/outsource'
import ContactUs from './screens/contact_us'
import Blogs from './screens/blogs'
import Service from './screens/services'
import BlogDetail from './screens/blog_detail'
import Admin from './screens/admin'
import AdminDashboard from './screens/admin_dashboard';
import AddUser from './screens/adduser';
import AllUser from './screens/viewusers';
import AddBlogs from './screens/addblog';
import ViewBlog from './screens/viewblogs';


class App extends Component {
  
  render() {
    return (
      <Router>
        <Switch> 
          <Route exact path="/" component={Home}/>
          <Route exact path="/about-us" component={AboutUS}/>
          <Route exact path="/outsource" component={OutSource}/>
          <Route exact path="/contact-us" component={ContactUs}/>
          <Route exact path="/blogs" component={Blogs}/>
          <Route exact path="/service" component={Service}/>
          <Route exact path="/blogs/:title/:id" component={BlogDetail}/>
          <Route exact path="/admin" component={Admin}/>
          <Route exact path="/dashboard" component={AdminDashboard}/>
          <Route exact path="/dashboard/new_user" component={AddUser}/>
          <Route exact path="/dashboard/all_users" component={AllUser}/>
          <Route exact path="/dashboard/add-blog" component={AddBlogs}/>
          <Route exact path="/dashboard/blog" component={ViewBlog}/>
          <Route exact path="/dashboard/update/:id" component={AddBlogs}/>
        </Switch>
      </Router>
    )  
  }
} 

export default App;