import styles from '../../styles/aboutus/our_team.module.css'


const team = [
    {
        name : 'Naveed Ahmed Javed' ,
        email : 'naveed@7ctech.com' ,
        position : 'CEO',
        image : 'https://res.cloudinary.com/seven-ctech/image/upload/v1625584230/website-Images/person_odrquo.jpg'
    },

    {
        name : 'Obaid Nizami' ,
        email : 'obaid@7ctech.com' ,
        position : 'PMO',
        image : 'https://res.cloudinary.com/seven-ctech/image/upload/v1625584230/website-Images/person_odrquo.jpg'
    },

    {
        name : 'Mohi uddin' ,
        email : 'mohiudeen@7ctech.com' ,
        position : 'Team Lead',
        image : 'https://res.cloudinary.com/seven-ctech/image/upload/v1625584230/website-Images/person_odrquo.jpg'
    },

    {
        name : 'Faheem' ,
        email : 'fahim@7ctech.com' ,
        position : 'Head of SQA',
        image : 'https://res.cloudinary.com/seven-ctech/image/upload/v1625584230/website-Images/person_odrquo.jpg'
    }
]



function CoreValues() {
    return ( 
        <div className={`col-12 p-0 m-0 ml-auto mr-auto`}>
          <div className={`col-12 col-md-7 col-xl-6  m-0 ml-auto mr-auto div_max_width `} style={{  padding : '3% 0%' }}>
              
              <h3 style={{ marginTop :'5%' , fontFamily: 'Montserrat' , letterSpacing: '0.05em' }} className="text-center font-weight-bold" > CONNECT WITH <h3  className="text_color_blue d-inline-block font-weight-bold" style={{fontFamily: 'Montserrat'}}> OUR TEAM </h3> </h3>

              <h6 style={{ marginTop : '2%' , fontFamily: 'times new roman' , color: '#3c3950' , letterSpacing: '0.05em' }} className="text-center text_color_grey col-11 ml-auto mr-auto" >OUR COMPANY HAS EXPANDED AND DEVELOPED OVER THE YEARS </h6>
  
              <div className="col-12 p-0 m-0 ml-auto mr-auto mt-5 row " >

                {  team.map((val , ind) => {
                    return(
                    <div className={`col-11 col-md-5 col-lg-5 col-xl-6 ml-auto mr-auto mt-5 mb-2 text-center show_pointer row border pt-5 pb-5 ${ styles.about_team_detail_div } `} style={{maxWidth: '500px'}} >  
                        
                        <div className="col-3 col-md-3 col-lg-3 col-xl-2 d-flex"  >
                            <img src={val.image} class={`align-self-center ${styles.about_team_img} `}/>
                        </div>
                        
                        <div className="col-9 col-md-9 col-lg-9 col-xl-10 text-left">
                            <h5 className={`text-left m-3`} > { val.name } </h5>
                            <p className="text-left text_color_grey  ml-auto mr-auto pl-3 m-1" style={{ fontSize : '16px' }} > {val.position} </p>
                            <a className="ml-3 m-1 text-left text_color_blue"  href={`mailto:${val.email}`} > <i class="far fa-envelope mr-1" style={{fontSize: '14px'}}></i> { val.email }  </a>
                        </div>
                        
                    </div>
                    )
                  })
                }
                
              </div>

          </div>
          <div className="col-12 col-md-4 col-xl-6">

          </div>
        </div>
    );
  }
   
  export default CoreValues;