import styles from '../../styles/aboutus/best_services.module.css'
import { useHistory } from "react-router";



const services = [
    {
        title : 'Dot Net Core Solutions' ,
        description : '.NET core is the next big thing after the release of revolutionary .NET and .NET 2.0. 7CTECH has been working and developing solutions using Microsoft .NET core technologies.' ,
        service_id : 6
    },
    {
        title : 'Customized Business Solutions' ,
        description : 'If you have a killer idea or a great startup thought lingering around in your mind for a long time?...' ,
        service_id : 7
    },
    {
        title : 'Shopify Development' ,
        description : 'Shopify is one of the biggest E-Commerce platforms and enablers and one of the best optimized one.' ,
        service_id : 8
    }
]


function BestServices() {
    
    const history = useHistory();

    const serviceDetail = (index) => {
        history.push("/service", { index : index })
      }
    

    return ( 
        <div className={`col-12  p-0 m-0 ml-auto mr-auto d-flex ${styles.about_us_best_service_div}`}>
          <div className={`col-12 col-lg-11 p-0 boder m-0 ml-auto mr-auto div_max_width`} >
              
              <h1 style={{ marginTop :'10%' }} className="text-center pt-5 mt-5 text_color_white font-weight-lighter ml-2 mr-2" style={{fontFamily: 'Montserrat' , letterSpacing: '0.05em'}} > OUR BEST <h1 className="text_color_blue d-inline-block font-weight-bold mt-2 pt-3" style={{letterSpacing: '0.05em'}} > SERVICES </h1> </h1>
  
              <div className="col-12 p-0 m-0 ml-auto mr-auto row mt-5" >

                {  services.map((val , ind) => {
                    return(
                    <div className={`col-12 col-sm-6 col-md-4 p-0 ml-auto mr-auto mt-5 pt-5 mb-5 text-center show_pointer ${ styles.about_service_detail_div } `} style={{position: 'relative'}} onClick={()=>{ serviceDetail(val.service_id) }} >  
                        <i class={`fas fa-check-circle ${styles.about_service_tick_icon} `}></i>
                        <h6 className={`text-center text_color_white  ${ styles.about_service_detail_title } m-3`} style={{fontSize: '18px'}} > { ` ${ val.title.toUpperCase() }` } </h6>
                        <p className="text-center col-11 ml-auto mr-auto pb-5" style={{ fontSize : '16px' , color: '#b4bdc5'}} > {val.description} </p>
                        <button style={{position: 'absolute' , bottom: '0px' , left: '28%' , right: '30%'}} className={`${styles.about_service_detail_btn} col-5 col-md-6 col-lg-5 m-2`} onClick={()=>{ serviceDetail(val.service_id) }} > MORE DETAILS </button> 
                    </div>
                    )
                  })
                }
                
              </div>

          </div>
        </div>
    );
  }
   
  export default BestServices;