import React, { Component } from 'react';
import styles from '../../styles/service/service.module.css'
import BestServices from '../../components/aboutus/best_services'
import { Link } from 'react-router-dom'
// import BlueDiv from '../../shared/blue_div/index'

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
// import 'react-tabs/style/react-tabs.css';


class Service extends React.Component {
  
 constructor(props){
     super(props)
     this.service_div_ref = React.createRef()  
     this.state = { 
         tabIndex : 0
     }
 }


 componentDidMount() {
     window.scrollTo(0 , 0)
     if(this.props.location.state && this.props.location.state.index ) {
        console.log('index' , this.props.location.state.index)
        this.setState({ tabIndex : this.props.location.state.index } , () => {
            this.service_div_ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
        }) 
     }
 }

 componentWillReceiveProps(next){
    console.log('next prop' , next)
    // if(next.location.state && next.location.state.index ) {
        this.setState({ tabIndex : next.location.state.index } , () => {
            this.service_div_ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
        }) 
    //  }
}

 serviceDetail(index){
    console.log('index' , index) 
    this.setState({ tabIndex : index } , () => {
        this.service_div_ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }) 
 }


  render(){
    return (
     <div className="col-12 ml-aut mr-auto p-0" >
        
         {/* bread crumb */}
         <div className={`breadcrumb_div`} >
                <h1 className="text_color_white text-center pt-5" > OUR SERVICES </h1>
                 <nav aria-label="breadcrumb ml-auto mr-auto text-center">
                    <ol className="breadcrumb justify-content-center" style={{ backgroundColor : 'transparent' }}>
                        <li className="breadcrumb-item"><a href="/">Home</a></li>
                        <li className="breadcrumb-item active text_color_white" aria-current="page"> Services </li>
                    </ol>
                 </nav>
         </div>


         <div className="col-12 ml-auto mr-auto mt-5 mb-5 justify-content-center row div_max_width">

            {
                service_card_arr.map( (val , ind ) => {
                    return(
                        <div className={`${styles.card} border`} style={{  width : '30%' , backgroundColor : '#f0f3f2' , margin : '1%' , position: 'relative'}} >
                            <img src={val.img} style={{ width : '100%' ,height : '200px' }} />
                            <div className="text-center pt-3 pb-3"  >
                                <h6 className="text-center m-2 col-11 ml-auto mr-auto" >  { val.title }  </h6>
                                <p className="text-center m-2 col-11 ml-auto mr-auto text_color_grey pb-5" style={{ fontSize : '13px' }} >
                                    { val.description }
                                </p>
                                <button style={{position: 'absolute' , bottom: '5%' , left: '30%' , right: '30%' }} className={`${ styles.service_read_btn} mt-3`} onClick={()=>{ this.serviceDetail(ind) } } > Read More </button>
                            </div>
                        </div>
                    )
                })
            }

         </div>

         <BestServices/>

         {/* Blue Div start */}
         <div className="col-12 text-center ml-auto mr-auto pt-5 pb-5  p-0 m-0" style={{ backgroundColor : '#21a3df' , minHeight : '200px' }} >

            <div  className="col-12 text-center ml-auto mr-auto row div_max_width m-0 p-0 "  >

                <div className="col-12 col-md-4 mr-auto ml-auto text-center text-lg-left pt-4 pb-4"  >
                    <img className="text-center align-self-center" src="https://res.cloudinary.com/seven-ctech/image/upload/v1625587510/website-Images/7ctech-black-logo_eowtvl.png" />
                </div>

                <div className="col-12 col-md-4 mr-auto ml-auto text-center mt-2 pt-4 pb-4 "  >
                    <h6 className="text_color_white  align-self-center font-weight-light" style={{fontSize: '20px' }} > WE’D LOVE TO HEAR FROM YOU </h6>
                </div>

                <div className="col-12 col-md-4 mr-auto ml-auto text-center text-lg-right pb-4 pt-4 " >
                    <Link to="/contact-us" className="align-self-center" >
                        <button className={`col-7 col-md-8 col-lg-7 ${styles.blue_slide_btn}`} style={{height: '50px' , letterSpacing: '0.05em'}} > CONTACT US </button>
                    </Link>
                </div>

            </div>

            </div>
         {/* Blue Div End */}

         <div ref={this.service_div_ref} className="col-12 col-lg-11 ml-auto mr-auto div_max_width" style={{ margin : '5% 0%' }} >

             <h1 className="text_color_grey text-center mt-3 mb-5 font-weight-light" > THINGS WE CAN <h1 className="text_color_blue d-inline-block" > DO FOR YOU </h1>  </h1> 
           
            <Tabs selectedIndex={this.state.tabIndex} onSelect={index => this.setState({  tabIndex : index })}  >
                <TabList>
                    <Tab> DEVELOPMENT </Tab>
                    <Tab> E-Commerce </Tab>
                    <Tab> WEB APPLICATION </Tab>
                    <Tab> MOBILE APP </Tab>
                    <Tab> DATA SCRAPING  </Tab>
                    <Tab> AI </Tab>
                    <Tab> DOT NET </Tab>
                    <Tab> CBS </Tab>
                    <Tab> SHOPIFY </Tab>
                </TabList>

                {
                    service_detail_arr.map((val , ind) => {
                        return(
                            <TabPanel className="border" >
                                <div className="p-3 danger_innerhtml" dangerouslySetInnerHTML={{__html:  service_detail_arr[ind].description}} />
                            </TabPanel>
                        )
                    })
                }
             
            </Tabs>

         </div>

     </div>
    )
  }
} 


const service_card_arr = [
    {
        img : 'https://res.cloudinary.com/seven-ctech/image/upload/v1625588290/website-Images/website_development_w9febn.jpg' ,
        title : 'DESIGN & DEVELOPMENT' ,
        description : 'Our dedicated team is working on WordPress, AngulerJS, NodeJS, PSDs and more..',
        url : 'service/#development'
    },
    {
        img : 'https://res.cloudinary.com/seven-ctech/image/upload/v1625589215/website-Images/ecommerce_sawfzf.svg' ,
        title : 'E-Commerce ' ,
        description : 'We offer customized E-Commerce services along with development in magento, WooCommerce..',
        url : 'services/#E-Commerce'
    },
    {
        img : 'https://res.cloudinary.com/seven-ctech/image/upload/v1625589330/website-Images/customize-web-development_pnmxpn.jpg' ,
        title : 'WEB APPLICATION' ,
        description : 'Customized web solutions for B2B services listing, lead management, supply chain..',
        url : 'services/#webapplication'
    },
    {
        img : 'https://res.cloudinary.com/seven-ctech/image/upload/v1625588543/website-Images/mobile_app_joqkpf.jpg' ,
        title : 'MOBILE APP' ,
        description : 'Mastering the art of creating mobile applications that offer services on responsive and native development',
        url : 'services/#mobapp'
    },
    {
        img : 'https://res.cloudinary.com/seven-ctech/image/upload/v1625589075/website-Images/bigdata_q1jk96.jpg' ,
        title : 'DATA SCRAPING' ,
        description : 'Data mining systems, text mining solutions, big data analysis, API Integrations and more',
        url : 'services/#datascraping'
    },
    {
        img : 'https://res.cloudinary.com/seven-ctech/image/upload/v1625588425/website-Images/artificial_intelligence_q7dqas.jpg' ,
        title : 'ARTIFICIAL INTELLIGENCE' ,
        description : 'As technology evolves, some of our best solutions are Python based AI and deep learning solutions.',
        url : 'services/#AI'
    },
    {
        img : 'https://www.saratechnologies.com/images/dot-net.png' ,
        title : 'DOT NET SOLUTION' ,
        description : '.NET core is the next big thing after the release of revolutionary .NET and .NET 2.0. 7CTECH has been working and developing solutions using Microsoft .NET core technologies.',
        url : 'services/#dotnet'
    },
    {
        img : 'https://res.cloudinary.com/seven-ctech/image/upload/v1625588290/website-Images/website_development_w9febn.jpg' ,
        title : 'CUSTOMIZED BUSINESS SOLUTION' ,
        description : 'If you have a killer idea or a great startup thought lingering around in your mind for a long time?..',
        url : 'services/#CBS'
    },
    {
        img : 'https://res.cloudinary.com/seven-ctech/image/upload/v1625588949/website-Images/shopify_n5gqbk.png' ,
        title : 'SHOPIFY DEVELOPMENT' ,
        description : 'Shopify is one of the biggest E-Commerce platforms and enablers and one of the best optimized one. 7CTECH has been providing services regarding the platform since many years now.',
        url : 'services/#shopify'
    }
]

const service_detail_arr = [
    
    {
        title : 'development' ,
        description : `
            <p style="text-align: justify; color:#666476; fontsize:'12px' ">Outsource your web designing and development needs to 7CTECH and significantly improve your profit.7CTECH have quality web designers and developers well versed in latest technologies and trends working under very experienced and understanding project managers.</p>
            <p style="text-align: justify; color:#666476;"><strong>Why outsource to 7CTECH?</strong></p>
            <ul style="text-align: justify; color:#666476;">
            <li>Cost effective due to low development cost.</li>
            <li>Deep attention to details.</li>
            <li>Excellent communication architecture.</li>
            <li>SEO friendly (On-page SEO)</li>
            <li>Mobile friendly (Responsive)</li>
            <li>Fresh design (Creativity)</li>
            </ul style="color:#666476;" >
            <p style="text-align: justify; color:#666476;"><strong>Overview of services:</strong></p>
            <p style="text-align: justify; color:#666476;">7CTECH have dedicated teams and individual resources offering following services.</p>
            <p style="text-align: justify; color:#666476;"><strong>WordPress:&nbsp;</strong>One of the MOST used Off the shelf CMS. 7CTECH have experts who can work on WordPress development and management to deep expertise.</p>
            <p style="text-align: justify; color:#666476;"><strong>AngulerJS:&nbsp;</strong>One or the upcoming modern platforms for the front end development. Making the development of dynamic front ends and responsive behavior a fun job.</p>
            <p style="text-align: justify; color:#666476;"><strong>NodeJS:&nbsp;</strong>Upcoming technology for creating very time sensitive and powerful web based APIs as well as web applications.</p>
            <p style="text-align: justify; color:#666476;"><strong>Custom</strong><strong>&nbsp;HTML:&nbsp;</strong>7CTECH has a huge list of great HTML platforms developed for many clients as well as we take proud in a big store designed and developed by our highly motivated front end and UX developers.</p>
            <p style="text-align: justify; color:#666476;"><strong>PSD designing:</strong>&nbsp;After so many years PSD templates are still proving to be a main platform for the front end designing, not only for the web but also mobile applications and web applications.</p>
        `
    },
    
    {
        title : 'E-Commerce' ,
        description : `
        <p style="text-align: justify; color:#666476;"><strong>E-Commerce Development is one of the most expensive services offered in USA, Australia, Europe and UAE.&nbsp;</strong>If you are a digital agency or an individual looking to get there business fully online with an online E-Commerce store, then contact 7CTECH now and get the best ROI. We not only develop the E-Commerce solutions but also look after the final delivery until it is reached to maturity and starts making real ROI.</p>
        <p style="text-align: justify; color:#666476;">Following are the services offered by 7CTECH in context of E-Commerce solutions.</p>
        <p style="text-align: justify; color:#666476;"><strong>Magento:</strong></p>
        <p style="text-align: justify; color:#666476;">One of the most used E-Commerce&nbsp;solution, and one of the most powerful as well! Magento offers a extremely long list of options and features that can cover any kind of requirement that you may have.</p>
        <p style="text-align: justify; color:#666476;"><strong>Custom</strong><strong>&nbsp;E-Commerce:</strong></p>
        <p style="text-align: justify; color:#666476;">You may have the need for a B2B solution? Or you may need a customized solution? Go ahead and contact 7CTECH&nbsp;right away. We can create and deliver any kind of service that you may need.</p>
        <p style="text-align: justify; color:#666476;"><strong>POS Integration:</strong></p>
        <p style="text-align: justify; color:#666476;">7CTECH have integrated different POS as well as ERP solution with there many different E-Commerce&nbsp;solutions. As in today&rsquo;s world there is a shear need for connection in-store POS systems or inventory management of the ERP solutions with the online E-Commerce solutions. This will not only make your online store more realistic but also make inventory management easy across all your systems .</p>
        <p style="text-align: justify; color:#666476;"><strong>WooCommerce:</strong></p>
        <p style="text-align: justify; color:#666476;">Best solution for small businesses and is based on one of the most used CMS in the world WordPress.</p>
        <p style="text-align: justify; color:#666476;"><strong>Other Systems:</strong></p>
        <p style="text-align: justify; color:#666476;">7CTECH also offers E-Commerce development in other platforms like Shopify, OpenCart, PrestaShop and many more.</p>
        <p style="text-align: justify; color:#666476;">If you have need for any of these please don&rsquo;t hesitate and contact now.</p>
        `
    },
    
    {
        title : 'Web Application' ,
        description : `<p style="text-align: justify; color:#666476;">One of the main expertise of 7CTECH is Customized Web Based Solutions Development.</p>
                <p style="text-align: justify; color:#666476;">You may be a business that is looking to automate any of it&rsquo;s BPO or you are a digital agency/software house that wants to develop a customized business solution for a certain agency, If so then 7CTECH is the best bet for you.</p>
                <p style="text-align: justify; color:#666476;"><strong>We have developed:</strong></p>
                <ul style="color:#666476;">
                <li style="text-align: justify;">B2B Services Listing System (BeopaarHub)</li>
                <li style="text-align: justify;">Local Craftsmen Services Listing</li>
                <li style="text-align: justify;">Customized and as per requirements Lead Management System.</li>
                <li style="text-align: justify;">Child&nbsp;Security and School Management System.</li>
                <li style="text-align: justify;">Economical Management Systems</li>
                <li style="text-align: justify;">Laboratory Report Cloud System</li>
                <li style="text-align: justify;">Medical Lab Management System</li>
                <li style="text-align: justify;">Supply Chain System</li>
            </ul>`
    },
    
    {
        title : 'Mobile APP' ,
        description : `
        <p style="text-align: justify; color:#666476;">In today&rsquo;s world no business is complete without a proper Mobile Application for offering there services.</p>
        <p style="text-align: justify; color:#666476;">Mobile Applications not only create an environment for selling your services but it also brings all your services and products right at the finger tips of your end customer.</p>
        <p style="text-align: justify; color:#666476;">7CTECH have mastered the art of creating mobile applications that offer the best of both worlds. Namely cost effectiveness and functionality.</p>
        <p style="text-align: justify; color:#666476;">Following are the frameworks/Platforms that we offer Mobile Development services on.<br /><strong><u>Ionic &ndash; Cordova &ndash; Responsive&nbsp;and Hybrid Apps:</u></strong></p>
        <p style="text-align: justify; color:#666476;">With the convergence of technology to generalized standards it is high-time that businesses understand and adapt the magic of hybrid applications.</p>
        <p style="text-align: justify; color:#666476;">Hybrid&nbsp;Application solutions from 7CTECH offer you great flexibility and cost effectiveness for getting your idea off the ground.</p>
        <p style="text-align: justify; color:#666476;">The hybrid app development department at 7CTECH is full of Quality and Speed Junkies. They will never settle for anything less then the best of the best.</p>
        <p style="text-align: justify; color:#666476;"><strong>Native Mobile Application Development: (iOS and Android)</strong></p>
        <p style="text-align: justify; color:#666476;">With the advent of powerful hybrid technologies there seems to be less need for native Development. But 7CTECH understands the need of highly specialized and targeted solution. Often times companies and startups would need very targeted and highly specialized services to be offered in mobile applications and some times these things are not optimal to do with hybrid platforms. For example graphics intensive applications are best written in native technologies and targeted platforms.</p>
        <p style="text-align: justify; color:#666476;">We at 7CTECH believe that each client has unique needs and requirements should be monitored and studied before suggesting a solution.</p>
        <p style="text-align: justify; color:#666476;">If you have any idea or need about mobile based solution then please do not hesitate to get free consultation and tell us more about your wish list. We would love to listen and suggest best solutions possible.</p>
        `
    },
    
    {
        title : 'data scripting' ,
        description : `
        <p style="text-align: justify; color:#666476;">If you are in business&nbsp;of collecting data from online resources or automating any of your business process then 7CTECH is the best partner you can get.</p>
        <p style="text-align: justify; color:#666476;">For last 10 years 7CTECH has worked on scraping and analyzing huge quantities for different clients.</p>
        <p style="text-align: justify; color:#666476;">We offer:</p>
        <ul style="color:#666476;" >
        <li style="text-align: justify;">Data Scrapers</li>
        <li style="text-align: justify;">Data Mining Systems</li>
        <li style="text-align: justify;">Text Mining Solutions</li>
        <li style="text-align: justify;">Big Data Analysis</li>
        <li style="text-align: justify;">Purchasing and Selling Bots</li>
        <li style="text-align: justify;">API integrations</li>
        <li style="text-align: justify;">Process Automation</li>
        </ul>
        `
    },
    
    {
        title : 'AI' ,
        description : `
        <p style="text-align: justify; color:#666476;">It is soon to be time that many of the manual labor jobs are replaced by the AI (Artificial Intelligence). As scary as it may seem this is the unavoidable outcome of the technology growth that is happening now a days. 7CTECH understands that! And we offer best possible Python based AI and deep learning solutions.</p>
            <p style="text-align: justify;">We offer:</p>
            <ul style="color:#666476;">
            <li style="text-align: justify;">TensorFlow&nbsp;Based Solutions</li>
            <li style="text-align: justify;">AI Bots</li>
            <li style="text-align: justify;">AI Chatting Systems.</li>
            <li style="text-align: justify;">Non-Invasive Client Servicing Bots.</li>
        </ul>
        `
    },
    
    {
        title : 'dot net' ,
        description : `
        <div class="gem-icon-inner" style="text-align: justify; color:#666476;">
            <p>.NET core is the next big thing after the release of revolutionary .NET and .NET 2.0. 7CTECH has been working and developing solutions using Microsoft .NET core technologies like WPF, WCF and razer pages.</p>
            <p>We have developed highly optimized, Architectural and design patterns driven solutions. Out expertise with Microsoft .NET technologies comprise of:</p>
            <ol>
            <li>CQRS (Command Query Responsibility Separation) and Databus patterns driven API development.</li>
            <li>Big data manipulation.</li>
            <li>High availability and highly reliable system design and development.</li>
            <li>Real time Write through cache implementation using proprietary concurrent accessible solutions.</li>
            <li>Razer pages based web development.</li>
            <li>.NET MVC based web applications and web solutions development.</li>
            <li>React.JS implementation iside .NET MVC.</li>
            </ol>
        </div>
        `
    },

    {
        title : 'cbs' ,
        description : `
        <div class="gem-icon-inner" style="color:#666476;">
        <p>If you have a killer idea or a great startup thought lingering around in your mind for a long time? 
        Or you want to have a nice mobile application for the online E-Commerce that you have. But you have limited budget as well as you do not know the detailed technical
        aspect of how it gets executed.Then you do not need to worry any more.</p> 
        <p>7CTECH offers end to end project based outsourcing services. We at 7CTECH believe that a project
        does not only depend on creating what is asked for rather we believe in creating and taking proud in success. We at 7CTECH believe that any project trusted in us completes 
        when it is a success in terms of achieving the goals for which our clients start/conceptualize there projects and products.End-to-end project based development services of
        7CETCH can be the best way to create you next great idea from just an idea to a reality. We will develop it, nourish it and stay beside you until the full maturity of your idea to a stagring success.</p></div>
        `
    },

    {
        title : 'shopify' ,
        description : `
            <div class="gem-icon-inner" style="text-align: justify; color:#666476; "> <p>  Shopify is one of the biggest E-Commerce platforms and enablers and one of the best optimized one. 7CTECH has been providing services regarding the platform since many years now. With the emphises on custom configures and rich store fronts and POS development. </p> </div>
                <div class="gem-icon-with-text-content">
                <div class="gem-icon-with-text-text">
                <div class="wpb_text_column wpb_content_element ">
                <div class="wpb_wrapper">
                <ol style="color:#666476;" >
                <li style="text-align: justify;">Storefront synchronization with the POS systems.</li>
                <li style="text-align: justify;">Inventory management.</li>
                <li style="text-align: justify;">Off Shopify shipping ,tracking and user management integration</li>
                <li style="text-align: justify;">Shopify plugin development , installation and configurations.</li>
                <li style="text-align: justify;">Stripe and PayPal custom integrations.</li>
                <li style="text-align: justify;">Plan (subscriptions) based sale system development.</li>
                <li style="text-align: justify;">Shopify Admin API implementation with custom management console development.</li>
                <li style="text-align: justify;">Shopify Graph API implementation for your ease of customer and services management.</li>
                </ol>
                </div>
                </div>
                </div>
            </div>` 
    }

 
] 


export default Service;