import { Component } from "react";
import Header from './adminHeader'
import SideBar from './sidebar'

class Layout extends Component {

  constructor(){
    super();
    this.state = { 
    //   div_margin_top : 80
    }
  }


  render() {
    return (
      <div className="p-0 m-0">
          <Header  />
          <div className="col-12 p-0 m-0 row">
          <div className="col-3 d-none d-lg-block p-0" style={{maxWidth: '240px' , height: 'auto' , backgroundColor: 'rgb(34,46,50)'}}>
            <SideBar/>
          </div>
          
          <div id="l_div" className="layout_div col m-0 p-0 "  >
            <section>{this.props.children}</section>
          </div>

          </div>       
      </div>
    );
  }
}

export default Layout;