import React, { Component } from 'react';
import  fetch  from 'isomorphic-fetch';

class AddUser extends React.Component {
  
    constructor(props){
        super(props)
        this.state={
            userArr : [],
            msg : '',
            isLoading : false
        }
    }

    componentDidMount(){
        this.getAllUser()
    }

    getAllUser(){
        this.setState({isLoading : true})
        fetch('https://server-7ctech.herokuapp.com/api/user/get_all' , {
            method : 'GET',
            headers: {
                'Content-Type': 'application/json'
            }})
            .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    if (data.success == false  ) {
                        // console.log('error email')
                        this.setState({ msg : data.info })
                    }
                    else {
                        // console.log('success email')
                        this.setState({  
                            userArr : data.data,
                            isLoading : false 
                        })
                    }
                }).catch((err) => {
                    console.log ('Error: ',err )
                })
        }
        
render(){

        return(
            <div className="">
            {
             this.state.isLoading &&
             <div style={{textAlign: 'center', marginTop: '20%'}}>
                <div className="spinner-border text-center" style={{width: '3rem', height: '3rem'}} role="status">
                    <span className="sr-only">Loading...</span>
                </div>
                <p className="mt-1">Fetching Data Please Wait...</p>
            </div>
            }

            {
            !this.state.isLoading &&
            <div className="">
            <h6 className="text-center mt-3 mb-3">View All User</h6>
            <div className="row col-11 ml-auto mr-auto mt-3 p-0" style={{color: 'black !important', overflowY: 'scroll' , maxHeight: '75vh'}}>
                {
                    this.state.userArr.length > 0 ?
                        
                    <table className="table">
                    <thead className="thead-light">
                        <tr>
                        <th scope="col">Id</th>
                        <th scope="col">Name</th>
                        <th scope="col">Email</th>
                        <th scope="col">Type</th>
                        <th scope="col">Created Date</th>
                        <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                       {  
                           this.state.userArr.map((val , i)=> {
                               return(
                                <tr key={i}>
                                <td>{val.id}</td>
                                <td>{val.name}</td>
                                <td>{val.email}</td>
                                <td>{val.usertype.utype}</td>
                                <td>{val.createdAt.substring(0,10)}</td>
                                <td></td>
                                </tr>
                               )
                           })
                        }
                      
                    </tbody>
                    </table>
                    : 
                    <p className="text-center col-12 mt-3"><b>No User Found...</b></p>
                    }
            </div>
            </div>
        }
        </div>
        );
    }

    }

export default AddUser;