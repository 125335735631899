import React, { Component } from 'react';
import Layout from '../layout/layout'
import Blog from '../components/blogs/blogs'
// import MetaTags from 'react-meta-tags'
import {Helmet} from "react-helmet";

class Blogs extends React.Component {
  
  componentDidMount(){
    window.scrollTo(0 , 0)
  }
  

  render(){
    return (
        <Layout>
          <Helmet>
            <title>Blogs - 7CTECH</title>
            <link rel="canonical" href="https://www.7ctech.com/blogs" />
            <meta property="og:locale" content="en_US" />
            <meta property="og:type" content="article" />
            <meta property="og:title" content="Blogs - 7CTECH" />
            <meta property="og:url" content="https://www.7ctech.com/blogs" />
            <meta property="og:site_name" content="7CTECH" />
          </Helmet>
           <Blog />
        </Layout>
      
    )
  }
} 

export default Blogs;