import React, { Component } from 'react';
import  fetch  from 'isomorphic-fetch';

class AddUser extends React.Component {
  
    constructor(props){
        super(props)
        this.state={
            name : '',
            email : '',
            pass : '',
            repass : '',
            msg : '',
            type_id: '',
            userType: []

        }
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
        // console.log('Value', e.target.value)
    }

    componentDidMount(){
        this.fetchUserType();
    }

    fetchUserType(){
        fetch('https://server-7ctech.herokuapp.com/api/usertype/get_all' , {
            method : 'GET',
            headers: {
                'Content-Type': 'application/json'
            }})
            .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    if (data.success == false  ) {
                        // console.log('error email')
                        this.setState({ msg : data.info })
                    }
                    else {
                        // console.log('success email')
                        this.setState({  
                            userType : data.data,
                            // isLoading : false 
                        })
                    }
                }).catch((err) => {
                    console.log ('Error: ',err )
                })
        
    }

    addUser(){

        const { name, email, pass, repass, msg , type_id } = this.state;
        
        if(name == '' || email == '' || pass == '' || repass == ''){
            this.setState({ msg : 'Please fill out all fields' })
        }
        else if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)){
            this.setState({ msg : 'Invalid Email Address' })
        }
        else if( pass.length < 6 ){
            this.setState({ msg : 'Password length must b 6 character' })
        }
        else if ( pass != repass){
            this.setState({ msg : 'Password does not match' })
        }
        else {            
            fetch('https://server-7ctech.herokuapp.com/api/user/create' , {
                method : 'POST' ,    
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    name : name,
                    email : email.toLowerCase(),
                    pass : pass,
                    type_id : type_id
                  }) 
                })  
                .then((response) => {
                        return response.json();
                    })
                    .then((data) => {
                        if (data.success == false  ) {
                            // console.log('error email')
                            this.setState({ msg : data.info })
                        }
                        else {
                            // console.log('success email')
                            this.setState({  
                                msg : data.info , 
                                name : '' , 
                                email : '',
                                pass : '',
                                repass : ''
                            })
                            setTimeout(() => {
                                this.setState({
                                    msg: ''
                                })
                            }, 2000)
                        }
                    }).catch((err) => {
                        console.log ('Error: ',err )
                        this.setState({ 
                            msg : "Some thing went wrong in submiting form. Please try later" 
                        })
                    })
                }
        }  
            
render(){
    return(
        <div className="">
            <h6 className="text-center mt-2 mb-2">Create New User</h6>
            <div className="row col-10 ml-auto mr-auto mt-3" style={{color: 'black !important'}}>
                <div className="col-6">

                    <div className="col-11 mb-2 mt-2 ml-auto mr-auto">
                        <span className="text_color_light_black">Name</span>
                        <input className="form-control" name="name" type="text" value={this.state.name} onChange={this.handleInput}/>
                    </div>

                    <div className="col-11 mb-2 mt-2 ml-auto mr-auto">
                        <span className="text_color_light_black">Password</span>
                        <input className="form-control" name="pass" type="password" value={this.state.pass} onChange={this.handleInput}/>
                    </div>

                    <div className="col-11 mb-2 mt-2 ml-auto mr-auto">
                        <span className="text_color_light_black">User Type</span>
                        <select className="form-control" name="type_id" onChange={this.handleInput}>
                            {
                                this.state.userType.map((val , i) => {
                                    return(
                                        <option value={val.id}>{val.utype}</option>
                                    )
                                })
                            }
                        </select>
                    </div>

                </div>

                <div className="col-6">
                    
                    <div className="col-11 mb-2 mt-2 ml-auto mr-auto">
                        <span className="text_color_light_black">Email</span>
                        <input className="form-control" name="email" type="email" value={this.state.email} onChange={this.handleInput}/>
                    </div>

                    <div className="col-11 mb-2 mt-2 ml-auto mr-auto">
                        <span className="text_color_light_black">Re-type Password</span>
                        <input className="form-control" name="repass" type="password" value={this.state.repass} onChange={this.handleInput}/>
                    </div>

                </div>

                <p className="text-center col-12"><button className="btn btn-primary col-2 mt-3" id="submit_data" onClick={()=>{this.addUser()}}>Submit</button></p>

                <p className="text-center col-12 mt-2" style={{color: 'green'}}>{ this.state.msg }</p>
            </div>
          
        </div>
        );
    }

    }

export default AddUser;