import React from 'react';
import styles from '../../styles/outsource/outsource.module.css'


function tables() {
  return (
        <>
           <div className={`col-12  ml-auto mr-auto div_max_width`} >
              

              {/* Desktop screen table  */}
              <div className="d-none d-lg-block mb-5 " >

               <h1 className={` ${ styles.font_heading } text_color_black text-center font-weight-bold m-5 col-11 `}> DEDICATED TEAMS VS. PROJECT BASED VS. DEDICATED RESOURCE </h1>
                <table class="table table-bordered col-12 table-responsive ml-auto mr-auto text-center p-0" style={{color : 'grey'}}>
                  <thead>
                    <tr>
                      <th scope="col" style={{ backgroundColor:'#ffde97' }} ></th>
                      <th scope="col text-center p-2"  style={{ backgroundColor : "#9b4ce4" , color : 'white' }} > DEDICATED TEAM </th>
                      <th scope="col text-center p-2"  style={{ backgroundColor : "#4f67e1" , color : 'white' }} > PROJECT BASED	</th>
                      <th scope="col text-center p-2"  style={{ backgroundColor : "#00bcd4" , color : 'white' }} > DEDICATED RESOURCE </th>
                    </tr>
                  </thead>
                  <tbody>

                    <tr>
                      <th scope="col" ></th>
                      {/* <th scope="col"> <img  src="https://res.cloudinary.com/seven-ctech/image/upload/v1626086626/website-Images/users-solid_agrwya.svg" width="200px" height="130px" /> </th> */}
                      {/* <th scope="col" > <img src="https://res.cloudinary.com/seven-ctech/image/upload/v1626086817/website-Images/project-diagram-solid_gatzbg.svg" width= "200px" height="130px" />	</th> */}
                      {/* <th scope="col" > <img src="https://res.cloudinary.com/seven-ctech/image/upload/v1626086816/website-Images/user-solid_vw777h.svg"  width="200px" height="130px" /> </th> */}
                      <th scope="col" > <i className="fas fa-users" style={{fontSize: '70px' , color: '#9b4ce4'}}></i> </th>
                      <th scope="col" > <i className="fas fa-laptop" style={{fontSize: '70px' , color: '#4f67e1'}}></i> </th>
                      <th scope="col" > <i className="fas fa-user" style={{fontSize: '70px' , color: '#00bcd4'}}></i> </th>

                    </tr>

                    <tr  style={{ padding : '20px'  , backgroundColor : '#f4f7f6' }} > 
                      <th className={`${styles.outsource_table_description}`}  scope="row" style={{ padding : '20px' }} > You will get: </th>
                      <td className={`${styles.outsource_table_description}`} style={{ padding : '20px' }} > A dedicated offshore staff or team of professionals who will work exclusively on your project 8 hours a day, 5 days a week </td>
                      <td className={`${styles.outsource_table_description}`} style={{ padding : '20px' }} > A team of professionals who will work on your requirements on per-project basis </td>
                      <td className={`${styles.outsource_table_description}`} style={{ padding : '20px' }} > An individual or more professionals who get hired by you, will work on project 8 hours a day, 5 days a week </td>
                    </tr>
                    
                    <tr style={{ padding : '20px' }} >
                      <th className={`${styles.outsource_table_description}`}  scope="row" style={{ padding : '20px' }} > How you pay: </th>
                      <td className={`${styles.outsource_table_description}`}  style={{ padding : '20px' }} > Pay per staff per month </td>
                      <td className={`${styles.outsource_table_description}`}  style={{ padding : '20px' }} > Pay per project </td>
                      <td className={`${styles.outsource_table_description}`}  style={{ padding : '20px' }} > Pay per resource per month </td>
                    </tr>

                    <tr  style={{ padding : '20px' , backgroundColor : '#f4f7f6' }}>
                      <th className={`${styles.outsource_table_description}`}  scope="row" style={{ padding : '20px' }}> Recommended for: </th>
                      <td className={`${styles.outsource_table_description}`}  style={{ padding : '20px' }} > Augmenting a specific, creative or technical skill gap in your team </td>
                      <td className={`${styles.outsource_table_description}`}  style={{ padding : '20px' }} > Projects that need both creative and technical expertise </td>
                      <td className={`${styles.outsource_table_description}`}  style={{ padding : '20px' }} > Lack of specific resource? Hire any individual resource </td>
                    </tr>
                  </tbody>
                  </table>

              </div>
              {/* End of Desktop screen table */}

              
              {/* Mobile Screen Table */}
              <div className="d-block d-lg-none p-0 m-0" >

                {/* mobile images table */}
                <table class="table table-bordered col-11 ml-auto mr-auto text-center" style={{ color : 'grey' }}>
                  
                  <thead>
                    <tr style={{ padding : '20px' }}>
                      <th scope="col" style={{ padding : '20px' }} ></th>
                      {/* <th scope="col" style={{ padding : '20px' }} ></th> */}
                    </tr>
                  </thead>
                  
                  <tbody>

                    <tr  style={{ backgroundColor : '#f4f7f6' }} >
                      <th scope="col" className="align-middle" > Dedicated Team </th>
                      <th scope="col"><i className="fas fa-users" style={{fontSize: '36px' , color: '#9b4ce4'}}></i> </th>
                    </tr>

                    <tr>
                      <th scope="col" className="align-middle" > Project Based </th>
                      <th scope="col"><i className="fas fa-laptop" style={{fontSize: '36px' , color: '#4f67e1'}}></i>	</th>
                    </tr>

                    <tr style={{ backgroundColor : '#f4f7f6' }}>
                      <th scope="col align-center" className="align-middle" > Dedicated Resource </th>
                      <th scope="col"> <i className="fas fa-user" style={{fontSize: '36px' , color: '#00bcd4'}}></i> </th>
                    </tr>

                  </tbody>

                </table>


                {/* mobile You will get: */}
                <table class="table table-bordered col-11 ml-auto mr-auto text-center" style={{ color : 'grey' }}>
                  
                  <thead>
                    <tr style={{ padding : '20px' }}>
                      <th scope="col" style={{ padding : '20px' }} ></th>
                      <th scope="col" style={{ padding : '20px' }} className="align-middle" > You will get: </th>
                    </tr>
                  </thead>
                  
                  <tbody>

                    <tr  style={{ backgroundColor : '#f4f7f6' }} >
                      <th scope="col" className="align-middle" > Dedicated Team </th>
                      <th scope="col" className="align-middle" > A dedicated offshore staff or team of professionals who will work exclusively on your project 8 hours a day, 5 days a week </th>
                    </tr>

                    <tr>
                      <th scope="col" className="align-middle" > Project Based </th>
                      <th scope="col" className="align-middle" > A team of professionals who will work on your requirements on per-project basis	</th>
                    </tr>

                    <tr   style={{ backgroundColor : '#f4f7f6' }}>
                      <th scope="col" className="align-middle" > Dedicated Resource </th>
                      <th scope="col" className="align-middle" > An individual or more professionals who get hired by you, will work on project 8 hours a day, 5 days a week </th>
                    </tr>

                  </tbody>

                </table>




                  {/* mobile How you pay: */}
                  <table class="table table-bordered col-11 ml-auto mr-auto text-center" style={{ color : 'grey' }}>
                  
                    <thead>
                      <tr style={{ padding : '20px' }}>
                        <th scope="col" style={{ padding : '20px' }} ></th>
                        <th scope="col" className="align-middle" style={{ padding : '20px' }} > How you pay: </th>
                      </tr>
                    </thead>
                    
                    <tbody>

                      <tr  style={{ backgroundColor : '#f4f7f6' }} >
                        <th scope="col" className="align-middle"  > Dedicated Team </th>
                        <th scope="col" className="align-middle" > Pay per staff per month </th>
                      </tr>

                      <tr>
                        <th scope="col" className="align-middle" > Project Based </th>
                        <th scope="col" className="align-middle" > Pay per project	</th>
                      </tr>

                      <tr   style={{ backgroundColor : '#f4f7f6' }}>
                        <th scope="col" className="align-middle" > Dedicated Resource </th>
                        <th scope="col" className="align-middle" > Pay per resource per month </th>
                      </tr>

                    </tbody>

                </table>
                




                 {/* Recommended for : */}
                 <table class="table table-bordered col-11 ml-auto mr-auto text-center" style={{ color : 'grey' }}>
                  
                  <thead>
                    <tr style={{ padding : '20px' }}>
                      <th scope="col" style={{ padding : '20px' }} ></th>
                      <th scope="col" className="align-middle" style={{ padding : '20px' }} > Recommended for: </th>
                    </tr>
                  </thead>
                  
                  <tbody>

                    <tr  style={{ backgroundColor : '#f4f7f6' }} >
                      <th scope="col" className="align-middle" > Dedicated Team </th>
                      <th scope="col" className="align-middle" > Augmenting a specific, creative or technical skill gap in your team </th>
                    </tr>

                    <tr>
                      <th scope="col" className="align-middle" > Project Based </th>
                      <th scope="col" className="align-middle" > Projects that need both creative and technical expertise	</th>
                    </tr>

                    <tr   style={{ backgroundColor : '#f4f7f6' }}>
                      <th scope="col" className="align-middle" > Dedicated Resource </th>
                      <th scope="col" className="align-middle" > Lack of specific resource? Hire any individual resource </th>
                    </tr>

                  </tbody>

              </table>
              
              </div>
              {/* End of Mobile Screen Table */}



           </div>
        </> 
  );
}
 
export default tables;


