import React, { Component } from 'react';
import styles from  '../../styles/admin/admin.module.css'
import {withRouter, useHistory} from 'react-router-dom'
import  fetch  from 'isomorphic-fetch';
// import RecentPost from '../../shared/footer/components/recent_post'
// import MetaTags from 'react-meta-tags'
// import  fetch  from 'isomorphic-fetch'


class Admin extends React.Component {

    constructor(props) {
        super(props);
        
        this.state = {
            email: '',
            pass: '',
            msg : ''
        }
      }

      handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    componentDidMount() {
        if(localStorage.getItem('userDetail') != null){
            this.setState({detail : JSON.parse(localStorage.getItem('userDetail'))})
            this.props.history.push('/dashboard/add-blog')
        }
        else{
            this.props.history.push('/admin')
        } 
        
      }

    submitLogin(){
    const { email, pass } = this.state;
        
        if(email == '' || pass == '' ){
            this.setState({ msg : 'Please fill out all fields' })
        }
        else if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)){
            this.setState({ msg : 'Invalid Email Address' })
        }
        else if( pass.length < 6 ){
            this.setState({ msg : 'Password length must b 6 character' })
        }
        else {            
            fetch('https://server-7ctech.herokuapp.com/api/user/find_by_email/login' , {
                method : 'POST' ,    
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    email : email.toLowerCase(),
                    pass : pass
                  }) 
                })  
                .then((response) => {
                        return response.json();
                    })
                    .then((data) => {
                        if (data.success == false  ) {
                            console.log('error email ' , data)
                            this.setState({ msg : data.info })
                        }
                        else {
                            console.log('success email')
                            this.setState({  
                                msg : data.info , 
                            })
                            setTimeout(() => {
                                this.setState({
                                    msg: ''
                                })
                            }, 2000)
                            console.log('Data ', data)
                            let detail ={
                                id : data.data.id,
                                name : data.data.name,
                                email : data.data.email,
                                createdAt : data.data.createdAt,
                                type_id : data.data.type_id    
                            }
                            localStorage.setItem("userDetail", JSON.stringify(detail));
                            this.props.history.push("/dashboard/add-blog");
                        }
                    }).catch((err) => {
                        console.log ('Error: ',err )
                        this.setState({ 
                            msg : "Some thing went wrong in Login. Please try later" 
                        })
                    })
                }
        }      

    render(){
      return (
            <div className={`${styles.adminDiv} shadow col-md-4 ml-auto mr-auto p-0`}>
                <h5 className="text-center mt-1">Sign In</h5>
                <div className="col-11 mt-3 ml-auto mr-auto">
                    <label className="mb-0">Email:</label>
                    <input className="col-12 ml-auto mr-auto form-control" name="email" onChange={this.handleInput} type="email" placeholder="Your Email" />
                </div>
                <div className="col-11 mt-3 ml-auto mr-auto">
                    <label className="mb-0">Password:</label>
                    <input className="col-12 ml-auto mr-auto form-control" name="pass" onChange={this.handleInput} type="password" placeholder="********" />
                </div>
                <p className="text-center mt-4"><button className="col-3 btn btn-primary" onClick={()=>{this.submitLogin()}}>Submit</button></p>

                <p className="text-center">{this.state.msg}</p>
            </div>
      )

    }
}


export default withRouter(Admin);