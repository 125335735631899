import React, { Component } from 'react';
import Layout from '../layout/layout'
import Services from '../components/services/services'
import {Helmet} from "react-helmet";
// import MetaTags from 'react-meta-tags'

class Service extends React.Component {
  

  componentDidMount(){
    window.scrollTo(0 , 0);
  }

  render(){
    return (
        <Layout>
          <Helmet>
          <title>Services - 7CTECH</title>
          <link rel="canonical" href="https://www.7ctech.com/services" />
          <meta property="og:locale" content="en_US" />
          <meta property="og:type" content="article" />
          <meta property="og:title" content="Services - 7CTECH" />
          <meta property="og:url" content="https://www.7ctech.com/services" />
          <meta property="og:site_name" content="7CTECH" />
          </Helmet>
          {      
              <Services location={this.props.location} />
          }
        </Layout>
      
    )
  }
} 

export default Service;