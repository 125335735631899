import React from "react";
import styles from '../../../styles/header/mobile_header.module.css'
import { slideInRight , slideInLeft , slideInDown} from 'react-animations'
import Radium, {StyleRoot} from 'radium';
import { withRouter  , Link} from "react-router-dom";
import { navBarLogo } from '../../../constants/index' 

const animation = {
   slide_right: {
    animation: '0.5s',
    animationName: Radium.keyframes(slideInRight , 'slideInRight')
  },

  slide_left:{
    animation: '0.5s',
    animationName: Radium.keyframes(slideInLeft , 'slideInLeft')
  },

  slide_down : {
    animation: '0.5s',
    animationName: Radium.keyframes(slideInDown , 'slideInDown')
  }

}

class HeaderMobile extends React.Component {

  constructor() {
    super();
     this.container = React.createRef();
     this.state = {
      MainBiscuitTab : false ,
      serviceTab: false,
      animate : animation.slide_down
     }
  }

  componentDidMount() {
     document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }


  // scrollDetect(){
  //   var header = document.getElementById('mobile_header')
  //    if(header != undefined && window != undefined ) {
  //     window.onscroll = (e) => {
  //       console.log('MainBiscuitTab' , this.state.MainBiscuitTab)
  //       if(window.scrollY) {
  //           if(window.pageYOffset > 700 && !this.state.MainBiscuitTab ) {
  //               header.classList.add('d-none')
  //               header.classList.remove('d-flex')
  //           } else {
  //             header.classList.add('d-flex')
  //             header.classList.remove('d-none')
  //           }
  //        }
  //      }
  //    }
  // }



  handleClickOutside = event => {
    console.log(event.target)
      //  check biscuit btn not pressed | check btn from drop down menu not pressed 
      if ( event.target.id != 'biscuit_btn' && this.container.current && !this.container.current.contains(event.target) &&  this.state.MainBiscuitTab == true) {
          this.setState({ MainBiscuitTab : false ,  serviceTab: false })
        }
  };

  openTab(e){ 
    this.setState({  MainBiscuitTab : false , [e] : true  })
  }

  closeTab(e){
    this.setState({ animate : animation.slide_left , [e] : false , MainBiscuitTab : true  })
  }

  scrollToTopButton() {
    window.scrollTo({top: 0, behavior: 'smooth'})
  }

  mainTabToogle(){
     this.setState(prevStat => ({ MainBiscuitTab : !prevStat.MainBiscuitTab , serviceTab : false}) )
  }

  
  serviceDetail(index){
    this.setState({  MainBiscuitTab : false , serviceTab : false } , () => {
      this.props.history.push("/service", { index : index })
    })
  }



  render(){
     const { MainBiscuitTab , serviceTab} = this.state;
    return (
      <StyleRoot>

      <div className="d-block d-md-none p-0 m-0">

        <div id="mobile_header" className={`${styles.mobile_main_div} p-0 m-0 row fixed-top `} >
            <div className={`col-8 p-0 m-0  ${styles.mobile_logo_div}`}  >
            <Link to="/" >
                <img  className={`${styles.mobile_logo} pt-auto pb-auto `} src={navBarLogo} alt="logo" />
            </Link>
            </div>
            <div  className={`col-4 p-0 m-0 ${ styles.mobile_tab_div }  `} >
                <i id="biscuit_btn" className={`fas fa-bars ${ styles.mobile_tab } show_pointer`} onClick={()=>{ this.mainTabToogle()  }}></i>
            </div>
        </div>

        { MainBiscuitTab &&
          
          <div ref={this.container} className={`ml-auto mr-auto p-0 m-0 ${styles.mobile_tab_menu_div} `}  style={ this.state.animate } >
           
              <div className={ `${ styles.tab_btn_div }` } > 
                <a className={`${ styles.tab_btn }`} href="/" > Home </a> 
              </div>

              <div className={ `${ styles.tab_btn_div }` } > 
                <a className={`${ styles.tab_btn }`} href="/about-us" > About Us </a> 
              </div>

              <div className={ `${ styles.tab_btn_div }` }   onClick={()=>this.openTab('serviceTab')} > 
                <b className={`${ styles.tab_btn }`} > Services </b>
                <i class="fas fa-chevron-right mt-1 mr-1" style={{ float : "right" }}></i>
              </div>

              <div className={ `${ styles.tab_btn_div }` }  > 
                <a className={`${ styles.tab_btn }`}  href="/outsource"> Outsource </a> 
              </div>

              <div className={ `${ styles.tab_btn_div }` } > 
                <a className={`${ styles.tab_btn }`} href="/blogs" > Blogs </a> 
              </div>

              <div className={ `${ styles.tab_btn_div }` }   > 
                <a className={`${ styles.tab_btn }`} href="/contact-us"  > Contact US </a> 
              </div>

         </div>

        }


        {
          serviceTab &&
            <div ref={this.container} className={`col-10 col-sm-10 ml-auto mr-auto p-0 m-0 ${styles.mobile_tab_menu_div}`}  style={ animation.slide_right }   >

                <div className={ `${ styles.tab_btn_div } text-right` }  onClick={()=>this.closeTab('serviceTab')} > 
                  {/* <i class="fas fa-arrow-left mt-1 ml-4" style={{ float : "left" }} ></i> */}
                  <b  className={`${ styles.tab_btn }`} > <i class="fa fa-times"></i> </b> 
                </div>
               
                <div className={ `${ styles.tab_btn_div }` } > 
                  <a className={`${ styles.tab_btn }`} onClick={ ()=> { this.serviceDetail(0) }} >DESIGN & DEVELOPMENT </a> 
                </div>

                <div className={ `${ styles.tab_btn_div }` } > 
                  <b className={`${ styles.tab_btn }`} onClick={ ()=> { this.serviceDetail(1) }} > ECOMMERCE </b> 
                </div>

                <div className={ `${ styles.tab_btn_div }` } > 
                  <b className={`${ styles.tab_btn }`} onClick={ ()=> { this.serviceDetail(3) }} > MOBILE APP </b>
                </div>

                <div className={ `${ styles.tab_btn_div }` } > 
                  <b className={`${ styles.tab_btn }`} onClick={ ()=> { this.serviceDetail(5) }} > ARTIFICIAL INTELLIGENCE </b> 
                </div>

                <div className={ `${ styles.tab_btn_div }` } > 
                  <b className={`${ styles.tab_btn }`} onClick={ ()=> { this.serviceDetail(6) }} > DOT NET SOLUTION</b> 
                </div>

                <div className={ `${ styles.tab_btn_div }` } > 
                  <b className={`${ styles.tab_btn }`} onClick={ ()=> { this.serviceDetail(7) }} > CUSTOMIZED BUSINESS SOLUTION </b> 
                </div>

                <div className={ `${ styles.tab_btn_div }` } > 
                  <b className={`${ styles.tab_btn }`} onClick={ ()=> { this.serviceDetail(8) }} > SHOPIFY DEVELOPMENT </b> 
                </div>


            </div>


        }
        

        {/* { this.state.show_bottom_scroll_mob == true &&
            <div id="scroll_btn" onClick={ ()=>{ this.scrollToTopButton() } } className={`show_pointer p-2 ${styles.scroll_btn}`} style={{bottom: '5%' , backgroundColor: 'rgb(123,124,128)' , height: '40px' , width: '40px' , right: '3%' , zIndex: '3000' , position: 'fixed' , color: 'white'}}>
               <p className="text-center"><i class="fa fa-angle-up" style={{fontSize: '24px'}}  ></i></p>
           </div>
          } */}
      
      </div>
      </StyleRoot>
    )
  }
  
}

export default withRouter(HeaderMobile);
