import React, { Component } from 'react';
import Layout from '../layout/layout'
import Slider from '../components/home/slider'
import Introduction from '../components/home/introduction'
import Service from '../components/home/services'
import Clients from '../components/home/clients'
import ContactUS from '../components/home/contact_us'
import Blogs from '../components/home/blogs'
import {Helmet} from "react-helmet";
// import MetaTags from 'react-meta-tags'


class Home extends React.Component {

  componentDidMount(){
    window.scrollTo(0 , 0)
  }

  render(){
    return (
      <Layout>
          <Helmet>
            <meta charSet="utf-8" />
            <title>Homepage - 7CTECH</title>
            <link rel="canonical" href="https://www.7ctech.com/" />
            <meta name="description" content="7cTech comprises of experienced and creative designers and web developers. We focus on customer satisfaction to build a long term work relationship with our clients." />
            <meta property="og:locale" content="en_US" />
            <meta property="og:type" content="website" />
            <meta property="og:title" content="Homepage - 7CTECH" />
            <meta property="og:url" content="https://www.7ctech.com/" />
            <meta property="og:site_name" content="7CTECH" />
          </Helmet>
          <Slider/>
          <Introduction/>
          <Service/>
          <Clients/>
          <Blogs/>
          <ContactUS/>
      </Layout>
    )
  }
} 

export default Home;