import React from 'react';
import styles from  '../../../styles/footer.module.css'
import  fetch  from 'isomorphic-fetch'
import Recaptcha from 'react-recaptcha';

 
// create a variable to store the component instance
let recaptchaInstance;

 
class  EmailUs extends React.Component {

   constructor(){
       super()
       this.state = {
           name : '' ,
           phone : '' ,
           email : '',
           interest : '',
           message : '',
           show_responce_msg : false,
           responce_msg : ''
       }

       this.recaptchaload = this.recaptchaload.bind(this);
       this.verifyCallback =this.verifyCallback.bind(this);
   }


   submitForm() {

    const { name , phone , email , interest , message } = this.state;

    console.log(`NAME ${name} , email ${email} phone ${phone} , interest ${interest} , message ${message}`)
    
    if(name == '' || phone == '' || email == '' || interest == '' || message == ''){
        this.setState({ show_responce_msg : true , responce_msg : 'Please fill out all fields' })
    }
    else if (!/^((\+92)|(0092))-{0,1}\d{3}-{0,1}\d{7}$|^\d{11}$|^\d{4}-\d{7}$/.test(phone))
    {
        this.setState({ show_responce_msg : true , responce_msg : 'Please enter valid mobile number' })
    }
    else if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email))
    {
        this.setState({ show_responce_msg : true , responce_msg : 'Please write valid email address' })
    }
    else {
        
        document.getElementById("submit_btn").disabled = true;
        
        fetch('https://server-7ctech.herokuapp.com/send' , {
            method : 'POST' ,    
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                name : name ,
                email : email,
                phone : phone ,
                interest : interest ,
                message : message
              }) 
            })  
            .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    if (data.success == false  ) {
                        document.getElementById("submit_btn").disabled = false;
                        this.setState({ show_responce_msg : true , responce_msg : data.message  })
                    }
                    else {

                        document.getElementById("submit_btn").disabled = false;
                        this.setState({ 
                            show_responce_msg : true , 
                            responce_msg : data.message , 
                            name : '' , 
                            phone : '' ,
                            email : '' ,
                            interest : '' ,
                            message : '' ,
                            is_submit : true
                        })
                    }
                }).catch((err) => {
                    
                    document.getElementById("submit_btn").disabled = false;
                    this.setState({ 
                        show_responce_msg : true , 
                        responce_msg : "Some thing went wrong in submiting form. Please try later" 
                    })
                })
            }
    }     


    recaptchaload(){
        console.log('captcha loaded sucessfully');
    }

    verifyCallback(response){
        console.log(' recaptcha responce' , response)
        if(response){
          this.setState({
            recaptcha_verified : true
          })
        }
      }
    

 render(){   
  return (
      <div className="col-11 col-md-11 ml-auto mr-auto  text-left p-0 m-0">
          <h6 className="text-left font-weight-bold col-11"  style={{ color:'white'}} > EMAIL US </h6>
          <br/>
          <br/>
            <div className="p-0">
            <div className="input-group col-md-12">
                <input className={`form-control shadow-none border`} style={{ backgroundColor:'rgb(24,24,40)' , color:'grey'  }} placeholder="Name * " value={this.state.name} onChange={ (e) => this.setState({ name : e.target.value }) } />
            </div>
        
            <div className="input-group col-md-12 mt-4">
                <input className={`form-control  border-right-0  shadow-none border`} style={{ backgroundColor:'rgb(24,24,40)' , color:'grey'  }} placeholder="Your Phone * " value={this.state.phone} onChange={ (e) => this.setState({ phone : e.target.value }) } />
                <span className="input-group-append">
                    <button className={`btn border border-left-0 shadow-none show_aero`} type="button" style={{ backgroundColor:'rgb(24,24,40)' }} >
                        <i className="fas fa-phone" style={{ color:'white' , fontSize: '14px' }} ></i>
                    </button>
                </span>
            </div>

            <div className="input-group col-md-12 mt-4">
                <input className={`form-control  border-right-0  shadow-none border`} style={{ backgroundColor:'rgb(24,24,40)' , color:'grey'  }} placeholder="Email * " value={this.state.email} onChange={ (e) => this.setState({ email : e.target.value }) } />
                <span className="input-group-append">
                    <button className={`btn border border-left-0 shadow-none show_aero`} type="button" style={{ backgroundColor:'rgb(24,24,40)' }} >
                        <i className="far fa-envelope" style={{ color:'white' , fontSize: '14px' }} ></i>
                    </button>
                </span>
            </div>

            <div className="input-group col-md-12 mt-4">
                <select className="form-control shadow-none border p-2"  style={{ backgroundColor:'rgb(24,24,40)' , color:'grey'  }} value={this.state.interest} onChange={ (e) => this.setState({ interest : e.target.value }) }>
                    <option className="bg-white" value="" > Scope of Interest? </option>
                    <option className="bg-white" value="web design / development" > Web Design / Development </option> 
                    <option className="bg-white" value="ecommerce website" > Ecommerce website </option>
                    <option className="bg-white" value="mobile application" > Mobile Application </option>
                    <option className="bg-white" value="custom software" > Custom Software </option>
                    <option className="bg-white" value="digital marketing" > Digital Marketing </option>
                    <option className="bg-white" value="out sourcing" > Out Sourcing </option>
                    <option className="bg-white" value=".Net" > Dot Net Solution </option>
                    <option className="bg-white" value="CBS" > Customized Business Solution </option>
                    <option className="bg-white" value="shopify development" > Shopify Development </option>
                </select>
            </div>

            <div className="input-group col-md-12 mt-4">
                <textarea className={`form-control shadow-none border`} style={{ backgroundColor:'rgb(24,24,40)' , color:'grey' , minHeight:'100px'  }} placeholder="Message * " value={this.state.message} onChange={ (e) => this.setState({ message : e.target.value }) }/>
            </div>

            
            <div className="col-12 p-3 text-center" style={{  minHeight : '100px'}}  > 
                <Recaptcha
                    ref={e => recaptchaInstance = e}
                    sitekey="6LeZh2QaAAAAAJxh1bq4e6XUtVmr8UiWYjLy7ynP"
                    render="explicit"
                    onloadCallback={this.recaptchaload}
                    verifyCallback={this.verifyCallback}
                />
            </div>

            {/* testing key
                sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI" */}

           
            <div className="input-group col-md-12 mt-4">
                <button className={`btn  col-12 shadow-none ${styles.contact_us_form_btn}`} onClick={() => { this.submitForm() }  }  id="submit_btn"> SUBMIT  </button>
            </div>

            {
              this.state.show_responce_msg &&
              <p className="p-2 m-1 m-3" style={{ border : 'solid 2px #ffb900' , color: '#89a9b5' , minHeight : '100px' , fontSize : '15px'}}  > { this.state.responce_msg } </p>
            }
            </div>
      </div>
     );
   }
}
export default EmailUs;