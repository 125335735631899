import React from "react";
import DesktopHeader from '../header/desktop/index'
import MobileHeader from '../header/mobile/index.js'
class Header extends React.Component {
  
  render(){
    return (
      <div className="p-0 m-0">
        <DesktopHeader   />
        <MobileHeader  />
      </div>
    )
  }
  
}

export default Header;
