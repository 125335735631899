import { Component } from "react";
import Header from '../shared/header/header'
import Footer from '../shared/footer/footer'

import { Router } from 'react-router';
import createHistory from 'history/createBrowserHistory';
const history = createHistory();

// history.listen((location, action) => {
//   console.log(action, location.pathname, location.state);
// });


class Layout extends Component {

  constructor(){
    super();
    this.state = { 
      div_margin_top : 80
    }
  }


 componentDidMount(){

  window.addEventListener("resize", ()=> {
    if(window && window.innerWidth > 767 && window.innerWidth < 992 ){
      // document.getElementById('l_div').style.marginTop = "170px"
      this.setState({ div_margin_top : 155 })
    }
    else{
      // document.getElementById('l_div').style.marginTop = "80px"
      this.setState({ div_margin_top : 80 })
    }
  });

 }

  render() {
  
    return (
      <div className="p-0 m-0">
        
          <Header  />
     
          <div id="l_div" className="layout_div" style={{ marginTop : `${this.state.div_margin_top}px` }}  >
            <section>{this.props.children}</section>
          </div>
        
          <Footer/>

      </div>
    );
  }
}

export default Layout;