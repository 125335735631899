import React from "react";
import styles from '../../../styles/header/desktop_header.module.css'
import { slideInUp } from 'react-animations'
import Radium, {StyleRoot} from 'radium';
import { withRouter } from "react-router-dom";

const animation = {

  slide_up : {
    animation: '1 0.5s',
    animationName: Radium.keyframes(slideInUp , 'slideInUp')
  }

}


class Tabs extends React.Component {

  constructor(){
      super();
      this.state = {
          show_searchbar : false ,
          pathArr : [ '/' , '/about-us' , '/service' , '/outsource' , '/blog'  , '/contact-us'   ]
      }
  }  


  
  componentDidMount(){
    console.log('path' , window.location.pathname)  
    
    if(window.location.pathname.includes('blogs')){
        document.getElementById(`tab_4`).classList.add('selected__path_tab');
    }
    else {
        this.state.pathArr.map( (val , ind) => {
            if(val.toLocaleLowerCase() == window.location.pathname.toLocaleLowerCase() ){
             document.getElementById(`tab_${ind}`).classList.add('selected__path_tab');
            }
            else{
                document.getElementById(`tab_${ind}`).classList.add('selected__path_tab1');
            }
        }) 
    }
  }


  serviceDetail(index){
    this.props.history.push("/service", { index : index })
  }

  
  render(){

    const { show_searchbar } = this.state;

    return (
        <StyleRoot>

        <div className={` ${styles.desktop_tabs} navbar navbar-expand p-0 text-right `}>
            <ul className={` navbar-nav pl-auto ml-auto pr-auto  mr-auto mr-lg-0 pr-lg-0`}>
                <li className="nav-item" id="tab_0"  >
                    <a className={`${styles.tab_btn} show_pointer `} href="/">HOME </a>
                </li>
                <li className="nav-item" id="tab_1">
                    <a className={`${styles.tab_btn} show_pointer`} href="/about-us">ABOUT US</a>
                </li>
                <li className={`nav-item dropdown`} id="tab_2">
                    <a className={`${styles.tab_btn} show_pointer`} href="/service" >SERVICES</a>
                    <div className={`dropdown-menu dropdown-menu-design w3-animate-bottom`} style={animation.slide_up} >
                        <a className="dropdown-item border-bottom show_pointer" onClick={ ()=> { this.serviceDetail(0) }}>DESIGN & DEVELOPMENT</a>
                        <a className="dropdown-item border-bottom show_pointer" onClick={ ()=> { this.serviceDetail(1) }}>ECOMMERCE</a>
                        <a className="dropdown-item border-bottom show_pointer" onClick={ ()=> { this.serviceDetail(2) }}>WEB APPLICATION</a>
                        <a className="dropdown-item border-bottom show_pointer" onClick={ ()=> { this.serviceDetail(3) }}>MOBILE APP</a>
                        <a className="dropdown-item border-bottom show_pointer" onClick={ ()=> { this.serviceDetail(4) }}>DATA SCRAPING</a>
                        <a className="dropdown-item border-bottom show_pointer" onClick={ ()=> { this.serviceDetail(5) }}>ARTIFICIAL INTELLIGENCE</a>
                        <a className="dropdown-item border-bottom show_pointer" onClick={ ()=> { this.serviceDetail(6) }}>DOT NET SOLUTION</a>
                        <a className="dropdown-item border-bottom show_pointer" onClick={ ()=> { this.serviceDetail(7) }}>CUSTOMIZED BUSINESS SOLUTION </a>
                        <a className="dropdown-item show_pointer" onClick={ ()=> { this.serviceDetail(8) }}>SHOPIFY DEVELOPMENT</a>
                    </div>
                </li>
                <li class="nav-item" id="tab_3">
                    <a className={`${styles.tab_btn} show_pointer`} href="/outsource"> OUTSOURCE </a>
                </li>
                <li class="nav-item" id="tab_4">
                    <a className={`${styles.tab_btn} show_pointer`} href="/blogs"> BLOGS </a>
                </li>
                <li class="nav-item " id="tab_5" >
                    <a className={`${styles.tab_btn} show_pointer`} href="/contact-us"> CONTACT US </a>
                </li>
                {/* <li class="nav-item">
           
                  <a className={`${styles.tab_btn} show_pointer`} > <i class="fas fa-search" style={{ fontSize : '16px' }} ></i> </a> */}
                    {/* <a className={`${styles.tab_btn} show_pointer`} >
                        <i class="fas fa-search" 
                            aria-hidden="true"  
                            onClick={()=>{ 
                                this.setState(prevState => ({
                                    show_searchbar: !prevState.show_searchbar
                                })); 
                            }}  
                        >  
                       </i>
                    </a> */}
                    {/* {!show_searchbar &&
                     <a className={`${styles.tab_btn} show_pointer`} >
                        <i className="fas fa-search" style={{fontSize : '12px'}} onClick={() => { this.setState({ show_searchbar : true }) } }> </i>
                     </a>
                    }
                    { show_searchbar &&
                    <a className={`${styles.tab_btn} show_pointer`} >
                        <i className="fas fa-times" style={{fontSize : '12px'}}  onClick={() => { this.setState({ show_searchbar : false }) } } ></i>
                    </a>
                    } */}
                   
                    {/* { show_searchbar &&
                      <div className={`border bg-white col-2 p-4 mr-5 ml-auto ${styles.search_div}`} >               
                        <div className={`${styles.search_inputContainer}`}>
                            <i className={`fas fa-search  ${styles.search_icon}`}> </i>
                            <input className={`${styles.search_Field } form-control`} type="text" placeholder="search here..." />
                        </div>
                      </div>
                    } */}

                {/* </li> */}
            </ul>
      </div>
      


      {/* pehly se bndhy */}
      {/* { show_searchbar &&
          <div className={`border bg-white col-2 p-4 mr-5 ml-auto ${styles.search_div}`} >               
             <div className={`${styles.search_inputContainer}`}>
                  <i className={`fas fa-search  ${styles.search_icon}`}> </i>
                  <input className={`${styles.search_Field } form-control`} type="text" placeholder="search here..." />
             </div>
          </div>
      } */}
      
      </StyleRoot>

    )
  }
  
}

export default withRouter(Tabs);
