import React, { Component } from 'react';
import styles from  '../../styles/blogs/blog.module.css'
import {withRouter} from 'react-router-dom'
import  fetch  from 'isomorphic-fetch'


class Blogs extends React.Component {

  constructor(){
    super();
    this.state = {
      blog_data : []
    }

}

componentDidMount(){
  window.scrollTo(0, 0);
  this.fetchBlogs();
}

async fetchBlogs() {
  // fetch('https://blogapi.7ctech.com/api/items' )
  fetch('https://server-7ctech.herokuapp.com/api/blog/get_all')  
      .then((response) => {
            return response.json();
        })
        .then((data) => {
            // console.log('blog data' , data)
            this.setState({ blog_data : data.data }) 
      });
}


// goToBlogDetail(data){
//   console.log('data', data)
//   var urlTitle = data.slug;
//   var a = document.createElement('a');
//   a.href = `/blogs/${data.slug}/${data.id}`
  
// }

blogDetail(data){
  var urlTitle = data.title;
  urlTitle = urlTitle.replace(/\s+/g, '-').toLowerCase();
  this.props.history.push(`/blogs/${data.slug}/${data.id}`)
}

  
  render(){
    return (
   
      <div className={`${styles.blog_background} col-12 p-0`} >
        {/* breadcrum blog div */}
        <div className={`${styles.breadcrumb_div}`} >
                <h1 className="text_color_white text-center pt-5" > BLOGS </h1>
                 <nav aria-label="breadcrumb ml-auto mr-auto">
                    <ol className="breadcrumb justify-content-center" style={{ backgroundColor : 'transparent' }}>
                        <li className="breadcrumb-item"><a href="/">Home</a></li>
                        <li className="breadcrumb-item active text_color_white" aria-current="page"> Blogs </li>
                    </ol>
                 </nav>
         </div>

          <div className="col-12  ml-auto mr-auto row d-flex justify-content-center div_max_width " style={{ paddingTop: '4%' , paddingBottom : '3%'}} >
            {
            this.state.blog_data.length > 0 ? (  
            this.state.blog_data.map((val , ind) => {
              if(val.status != 0) 
              return(
                <div className="col-12 col-md-6 col-lg-4 ml-auto mr-auto mt-3 mb-3 p-3" >
                  <div className={`${styles.container} col-12 p-0`} onClick={()=>{this.blogDetail(val)}} > 
                    <img width="100%" className={`${styles.image}`}  src={val.imgurl}/>
                    { 
                      val.imgurl &&
                    <div className={`${styles.imagehover}`} >
                      <div className={`${styles.hovericon}`}><i class="far fa-file-alt" style={{fontSize: '20px'}}></i></div>
                    </div>
                    }
                  </div>
                  <div className={`bg-white p-2`}>
                    {/* <p className="ml-2 mt-1 " style={{fontSize: '14px'}}>By <a className={`${styles.hover_black}`} href="/" title="Visit admin's Website"><span>admin</span></a> <i class="fas fa-heart mr-1 mt-1" style={{float: 'right'}}> {val.like}</i></p> */}
                    <a className={`${styles.heading_hover}`} style={{textDecoration: 'none' , color: 'black'}} href={`/blogs/${val.slug}/${val.id}`}><p className={`ml-2 mr-2 ${styles.heading_hover}`}  style={{fontSize: '20px'}}><b>{ val.createdAt.substr(0 , 10)}:</b> {val.title.substr(0 , 50)}</p></a>
                    <p className="ml-2 mr-2 text_color_grey " style={{fontSize: '14px'}} >{`${val.content.replace( /(<([^>]+)>)/ig, ' ').substr(3 , 100)}...`}</p>
                      <a href={`/blogs/${val.slug}/${val.id}`}  ><button  className={`ml-2 col-6 col-md-5 mb-3  ${styles.read_more_button}`}>Read More</button></a>
                  </div>
                </div>
              )
            }
            )
             ) : (
             <div className="text-center" style={{ marginTop : '10%' }} >
                  <div class="spinner-border" style={{ width: '3rem' , height: '3rem'}} role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                <p className="text-center mt-5" > Loading Please Wait </p>
             </div>
             )
              }

          </div>
          
      </div> 
         
    )
  }
} 

export default withRouter(Blogs);