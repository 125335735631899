import React, { Component } from 'react';
import Layout from '../layout/layout'
import BlogDetail from '../components/blogdetail/blog_detail'

class Blogs extends React.Component {
  
  componentDidMount(){
    window.scrollTo(0 , 0)
  }
  

  render(){
    return (
        <Layout>
           <BlogDetail location={this.props.location} />
        </Layout>
      
    )
  }
} 

export default Blogs;