import React, { Component } from 'react';
// import { CKEditor } from 'ckeditor4-react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import  fetch  from 'isomorphic-fetch';

export default class AddBlog extends React.Component {
  
    constructor(props){
        super(props)
        this.state={
            id : 0,
            title: '',
            content: '',
            imgurl : '',
            slug : '',
            status: 1,
            createdby: '',
            metatitle: '',
            metadescription: '',
            msg : '',
            update : false,
            imgupdate : false
        }
    }
    
    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
        console.log('Status' , e.target.value)
    }

    handleInputTitle = (e) => {
        this.setState({
            title: e.target.value,
            slug : e.target.value.replace(/\s+/g, '-').toLowerCase()
        })
    }
    
    onEditorChange ( evt ) {
        this.setState( {
            content: evt.editor.getData(),
        });
        console.log('Data', this.state.content)
    }

    componentDidMount(){
        var url = window.location.pathname;
        if(url.includes('/update')){
            this.fetchBlogDetail();
            this.setState({update :  true})
        }
        else{
            this.setState({update : false})
        }
    }

    fetchBlogDetail() {
        var url = window.location.pathname;
        var id = url.substring(url.lastIndexOf('/') + 1 , url.length);
        this.setState({id : id})

        fetch(`https://server-7ctech.herokuapp.com/api/blog/find_by_id/ `+id)  
        .then((response) => {
              return response.json();
          })
          .then((data) => {
              this.setState({ 
                  title : data.data.title,
                  content: data.data.content,
                  imgurl : data.data.imgurl,
                  status: data.data.status,
                  createdby: data.data.createdby,
                  metatitle: data.data.metatitle,
                  slug : data.data.slug,
                  metadescription: data.data.metadescription
                }) 
                // console.log('Content' ,data.data.content)
          });
      }

    handleImage = (e) => {
        let files = e.target.files;
        let data = new FormData();
        data.append('file',files[0])
        data.append('upload_preset', 'blog_images')

        fetch("https://api.cloudinary.com/v1_1/seven-ctech/image/upload",{
            method: 'POST',
            body : data
            }).then((response) => {
                return response.json();
            })
            .then((res) => {
                this.setState({ imgurl : res.secure_url })
            }).catch((err) => {
                console.log ('Error: ',err )
            })
    }

    updateBlog(){
        
        const { title, content, imgurl, createdby, status, metatitle, metadescription, msg, slug , id } = this.state;
        if(title == '' || createdby == '' || metatitle == '' || metadescription == '' || content == '' || status == '' ){
            this.setState({ msg : 'Please fill out all fields' })
        }
        else if( status != 0 && status != 1){
            this.setState({ msg : 'Status value should be 0 or 1' })
        }
        else {            
            fetch('https://server-7ctech.herokuapp.com/api/blog/update' , {
                method : 'PUT' ,    
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    id : id,
                    title : title,
                    content : content,
                    imgurl : imgurl,
                    createdby : createdby,
                    status : status,
                    slug : slug,
                    metatitle : metatitle,
                    metadescription : metadescription
                  }) 
                })  
                .then((response) => {
                        return response.json();
                    })
                    .then((data) => {
                        if (data.success == false  ) {
                            console.log('error')
                            this.setState({ msg : data.info })
                        }
                        else {
                            console.log('Blog Updated Sucessfully')
                            this.setState({  
                                msg : data.info , 
                                // title : '',
                                // content : '',
                                // imgurl : '',
                                // createdby : '',
                                // status : '',
                                // metatitle : '',
                                // metadescription : '',
                                // slug : ''
                            })
                            setTimeout(() => {
                                this.setState({
                                    msg: ''
                                })
                            }, 2000)
                        }
                    }).catch((err) => {
                        console.log ('Error: ',err )
                        this.setState({ 
                            msg : "Some thing went wrong in submiting form. Please try later" 
                        })
                    })
                }
    }

    submitBlog(){
        const { title, content, imgurl, createdby, status, metatitle, metadescription, msg, slug } = this.state;
        if(title == '' || createdby == '' || metatitle == '' || metadescription == '' || content == '' || status == '' ){
            this.setState({ msg : 'Please fill out all fields' })
        }else if( status != 0 && status != 1){
            this.setState({ msg : 'Status value should be 0 or 1' })}
        else {            
            fetch('https://server-7ctech.herokuapp.com/api/blog/create' , {
                method : 'POST' ,    
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    title : title,
                    content : content,
                    imgurl : imgurl,
                    createdby : createdby,
                    status : status,
                    slug : slug,
                    metatitle : metatitle,
                    metadescription : metadescription
                  }) 
                })  
                .then((response) => {
                        return response.json();
                    })
                    .then((data) => {
                        if (data.success == false  ) {
                            console.log('error')
                            this.setState({ msg : data.info })
                        }
                        else {
                            console.log('Blog Added Sucessfully')
                            this.setState({  
                                msg : data.info , 
                                title : '',
                                content : '',
                                imgurl : '',
                                createdby : '',
                                status : '',
                                metatitle : '',
                                metadescription : '',
                                slug : ''
                            })
                            setTimeout(() => {
                                this.setState({
                                    msg: ''
                                })
                            }, 2000)}
                    }).catch((err) => {
                        console.log ('Error: ',err )
                        this.setState({ 
                            msg : "Some thing went wrong in submiting form. Please try later" 
                        })
                    })
                }
        }  

render(){

        return(
            <div className="">
            <h6 className="text-center mt-2 mb-2">{this.state.update ? 'Update Blog' : 'Add New Blog'}</h6>
            <div className="row col-11 ml-auto mr-auto mt-3" style={{color: 'black !important'}}>
                <div className="col-6">

                    <div className="col-11 mb-2 mt-2 ml-auto mr-auto">
                        <span className="text_color_light_black">Title </span>
                        <input className="form-control" name="title" value={this.state.title} type="text" onChange={this.handleInputTitle}/>
                    </div>

                    {
                     this.state.update ?
                     this.state.imgupdate ?
                    <div className="col-11 mb-2 mt-2 ml-auto mr-auto">
                        <span className="text_color_light_black">Blog Image </span>
                        <input className="form-control" type="file" name="file" onChange={this.handleImage}/>
                    </div>
                   :
                    <div className="col-11 mb-2 mt-2 ml-auto mr-auto">
                        <img src={this.state.imgurl} width="250px" height="150px" />
                        <br/>
                        <p className="ml-5 pl-3 mt-2"><button className="btn btn-primary" onClick={()=>{this.setState({ imgurl : '' , imgupdate : true })}}>Update Image</button></p>
                    </div>
                    :
                    <div className="col-11 mb-2 mt-2 ml-auto mr-auto">
                        <span className="text_color_light_black">Blog Image </span>
                        <input className="form-control" type="file" name="file" onChange={this.handleImage}/>
                    </div>
                    }

                    <div className="col-11 mb-2 mt-2 ml-auto mr-auto">
                        <span className="text_color_light_black">Meta Title </span>
                        <input className="form-control" name="metatitle" value={this.state.metatitle} type="text" onChange={this.handleInput}/>
                    </div>

                </div>

                <div className="col-6">
                    <div className="col-11 mb-2 mt-2 ml-auto mr-auto">
                        <span className="text_color_light_black">Author Name </span>
                        <input className="form-control" name="createdby" value={this.state.createdby} type="text" onChange={this.handleInput}/>
                    </div>
                    <div className="col-11 mb-2 mt-2 ml-auto mr-auto">
                        <span className="text_color_light_black">Status</span>
                        <input className="form-control" maxLength="1" min="0" max="9" name="status" value={this.state.status} placeholder="Either 0 or 1" type="number" onChange={this.handleInput}/>
                    </div>
                    <div className="col-11 mb-2 mt-2 ml-auto mr-auto">
                        <span className="text_color_light_black">Meta Description </span>
                        <textarea className="form-control" name="metadescription" value={this.state.metadescription} type="text" rows="3" onChange={this.handleInput}></textarea>
                    </div>
                </div>

                <div className="col-11 mt-2 ml-auto mr-auto">
                    <span className="text_color_light_black">Blog Content</span>
                    <CKEditor editor={ ClassicEditor } data={this.state.content} 
                    onReady={ editor => {
                        // You can store the "editor" and use when it is needed.
                        console.log( 'Editor is ready to use!', editor );
                    } }
                    onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        this.setState({content : data})
                        console.log('Content' , this.state.content);
                    } }
                    // onChange={(e)=>{this.onEditorChange(e)}}
                    />
                </div>

                <p className="text-center col-12"><button className="btn btn-primary col-2 mt-3" onClick={()=>{{ this.state.update ? this.updateBlog() : this.submitBlog()}}}>{ this.state.update ? 'Update' : 'Submit' }</button></p>
                <p className="text-center col-12 mt-2" style={{color: 'green'}}>{ this.state.msg }</p>

            </div>
          
        </div>
        );
    }

    }

// export default AddBlog;