import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'

class AdminHeader extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            detail: {}
        }
    }

    logoutConfirm() {
        var x = window.confirm("Are you sure want to logout ?");
        if (x) {
            alert("logout Sucessfully")
            localStorage.removeItem("userDetail");
            this.props.history.push('/admin')
        }
        else
            return false;
    }

    componentDidMount() {
        if(localStorage.getItem('userDetail') != null){
            this.setState({detail : JSON.parse(localStorage.getItem('userDetail'))})
        }
        else{
            this.props.history.push('/admin')
        }
    }



    render() {
        return (
            <div className="ml-auto mr-auto p-1 pl-2" style={{ backgroundColor: '#3c8dbc', color: 'white', minHeight: '5vh' }}>
                <div className="col-12 row p-0 m-0" >
                    <div className="col-10 p-0 text-left">
                        <h6>7ctech Admin Dashboard</h6>
                    </div>
                    <div className="dropdown col-2 pt-2 pl-5">
                        <a className="dropdown-toggle" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ color: 'white' }}>
                            {this.state.detail.name}
                        </a>
                        <div className="dropdown-menu p-1 ml-5" aria-labelledby="dropdownMenuLink">
                            <a className="dropdown-item" onClick={() => { this.logoutConfirm() }} style={{ cursor: 'pointer' }}>Logout</a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export default withRouter(AdminHeader);