import React from "react";
import styles from '../../styles/footer.module.css'
import Introduction from './components/introduction'
import RecentPost from './components/recent_post'
import Contact from './components/contact_us'
import Email from './components/email_us'
import { fbUrl , likedinUrl , twitterUrl } from '../../constants/index'

class Footer extends React.Component {
  
  render(){
    return (
      <div className="col-12 p-0 m-0 " style={{ width : 'auto' }} >
          <div className={`col-12 p-0 m-0  ${styles.footer_main_content_div} `}>
            
            <div className="`col-12  col-lg-11 p-0 m-0 row justify-content-center ml-auto mr-auto div_max_width">
               
               <div className="col-12  col-md-12 col-lg-6 p-0 m-0 row"  >
                  
                  <div className="col-12 col-md-6 p-2 m-0" >
                     <Introduction />
                  </div>

                  <div className="col-12 col-md-6 p-2 m-0" >
                     <RecentPost />
                  </div>

               </div>


               <div className="col-12 col-md-12 col-lg-6 p-0 m-0 row" >
                  
                  <div className="col-12 col-md-6 p-2 m-0" >
                     <Email/>
                  </div>

                  <div className="col-12 col-md-6 p-2 m-0" >
                     <Contact/>
                  </div>

               </div>

            </div>
            
          </div>
          
          <hr className="p-0 m-0 bg_color_grey"/>

          <div className={`col-12 p-0 m-0  ${styles.footer_sub_content_div}`}>
              
              <div className="col-12 row ml-auto mr-auto pb-2 div_max_width" >

                  <div className="col-12 col-lg-3 p-2 m-0 text-center mt-1 mt-lg-4" >
                    <p className="text_color_grey" style={{fontSize: '15px'}}> 2021 © Copyrights <a href="/" style={{ color :  'rgb(26,132,224)' }} > 7CTECH </a>  </p>
                  </div>

                  <div className={`col-12 col-lg-7 p-2 m-0 row d-flex justify-content-center mt-1 mt-lg-4 ${styles.a}`}  >
                    
                       <a className={`show_pointer pr-2 ${styles.footer_tab_color}`} href="/" > Home <i className="vl"></i> </a>
                       <a className={`show_pointer pr-2 ${styles.footer_tab_color}`} href="/about-us" > About Us <i className="vl" ></i> </a>
                       <a className={`show_pointer pr-2 ${styles.footer_tab_color}`} href="/service" > Services <i className="vl" ></i> </a>
                       <a className={`show_pointer pr-2 ${styles.footer_tab_color}`} href="/outsource" > Outsource <i className="vl" ></i> </a>
                       <a className={`show_pointer pr-2 ${styles.footer_tab_color}`} href="/blogs" > Blogs <i className="vl" ></i> </a>
                       <a className={`show_pointer pr-2 ${styles.footer_tab_color}`} href="/contact-us" > Contact Us  <i className="" ></i> </a>
                    
                  </div>

                  <div className="col-12 col-lg-2 row d-flex justify-content-center mt-1 mt-lg-4 pt-2" >
                      <a href={fbUrl}><i style={{ fontSize : '20px' , color:'#4267B2' }} class="social_icon  fab fa-facebook-f ml-2 mr-2 show_pointer"></i></a>
                      <a href={likedinUrl}><i style={{ fontSize : '20px' , color:'#00B2FF' }} class="social_icon fab fa-linkedin-in ml-2 mr-2 show_pointer"></i></a>
                      <a href={twitterUrl}><i style={{ fontSize : '20px' , color:'#1DA1F2' }} class="social_icon fab fa-twitter ml-2 mr-2 show_pointer"></i></a>
                      {/* <a><i style={{ fontSize : '20px' , color:'#405DE6' }} class="social_icon fab fa-instagram ml-2 mr-2 show_pointer"></i></a> */}
                  </div>


              </div>

          </div>

         
      </div>
    )
  }
  
}

export default Footer;
