import React from 'react';
import styles from '../../styles/aboutus/animated_text.module.css' 
import { slideInRight , bounce} from 'react-animations'
import Radium, {StyleRoot} from 'radium';
import { useHistory } from "react-router";
import { Link } from 'react-router-dom';

const animation = {
  
  slide_right: {
   animation: '3s',
   animationName: Radium.keyframes(slideInRight , 'slideInRight')
 },

 bounce_text:{
   animation: '3s',
   animationName: Radium.keyframes(bounce , 'bounce')
 },


}

function Slider() {

  const history = useHistory();

  return (
      // <StyleRoot > 
            <div className={`col-12  p-0 m-0 ml-auto mr-auto d-flex ${styles.about_us_animated_div}`}>
                <div  className="justify-content-center align-self-center p-0 m-0 col-12 div_max_width ml-auto mr-auto" >
                    <h1 className="text_color_white text-center col-11 col-md-11 ml-auto mr-auto " style={animation.slide_right} > WE ARE 7CTECH</h1>
                    <h6 className="text_color_white text-center col-11 col-md-11 ml-auto mr-auto mt-5 font-weight-light" style={animation.bounce_text} > we strive to make sure that service we deliver is not just a complete high quality service, but also an innovative solution that can assure return on investment for our clients and ourselves.  </h6>
                    
                    <div className="text-center" style={{ marginTop : '10%' }} onClick={()=>{ history.push('/service')}}>
                      <Link to="/service">
                      <button className={`${styles.service_btn}`} style={{height: '50px' , letterSpacing: '0.05em'}} ><a  style={{color: 'white' , textDecoration: 'none'}}> OUR SERVICES </a></button>
                      </Link>
                    </div>

                </div>
            </div>
        // </StyleRoot> 
  );
}

 
export default Slider;